<!--
	@author bluefirex
-->
<template>
	<div class="acquisit-state-inspector-store-view default-wrapper">
		<StateInspectorHeader class="header">
			<template #default>
				<h2>{{ storeId }}</h2>
			</template>
		</StateInspectorHeader>
		
		<StateInspectorJsonView :json="store.$state" class="json" />
	</div>
</template>

<script setup lang="ts">
	import StateInspectorJsonView from '@ui/dev/StateInspectorJsonView.vue'
	import StateInspectorHeader from '@ui/dev/StateInspectorHeader.vue'
	
	const props = defineProps({
		store: {
			type: Object,
			required: true
		},
		
		storeId: {
			type: String,
			required: true
		}
	})
</script>

<style lang="scss">
	@use '@/assets/mixins.scss' as m;
	
	.acquisit-state-inspector-store-view {
	
	}
</style>