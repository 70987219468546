import config from '@config'
import { DateTimeExt } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { computed } from 'vue'
import { useGenericStore } from '@/stores/generic.ts'

const isLocalStorageAvailable = (): boolean => {
	try {
		localStorage.setItem('test', 'test')
		localStorage.removeItem('test')
		return true
	} catch (e) {
		return false
	}
}

export const useMaintenance = () => {
	const startTimestamp = config.maintenance.start,
		endTimestamp = config.maintenance.end
	
	const startDate = startTimestamp ? DateTimeExt.fromTimestamp(startTimestamp) : null,
		endDate = endTimestamp ? DateTimeExt.fromTimestamp(endTimestamp) : null
	
	const now = DateTimeExt.now()
	const isEnabled = computed(() => {
		// Check whether we're a dev
		const urlParams = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop as string),
		})
		
		if (isLocalStorageAvailable()) {
			const letMeIn = (<any> urlParams).letmein
			
			if (letMeIn == 'plz') {
				localStorage.setItem('maintenance_ghost', '1')
			} else if (letMeIn == 'reset') {
				localStorage.removeItem('maintenance_ghost')
			}
			
			if (localStorage.getItem('maintenance_ghost') === '1') {
				return false
			}
		}
		
		if (startDate) {
			// Tell generic store
			useGenericStore().next_maintenance_start = startDate
		}
		
		if (startDate && !endDate) {
			return now.isAfter(startDate)
		} else if (!startDate && endDate) {
			return now.isBefore(endDate)
		} else if (startDate && endDate) {
			return now.isBetween(startDate, endDate, 'second')
		} else {
			return config.maintenance?.message !== undefined
		}
	})
	
	return {
		isEnabled,
		startDate,
		endDate,
		message: config.maintenance.message
	}
}