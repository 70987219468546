<template>
	<div class="acquisit-legacy-product-person-chooser">
		<acquisit-header class="header" v-if="header_label && (hasRequiredPersons || !no_persons_label)" :label="header_label" />
		
		<!--
			Group responding to the "seller" role (not type!)
		-->
		<section :class="['seller']" v-if="role_seller_label && (hasRequiredPersons || !no_persons_label)">
			<h3 v-if="shouldShowHeadings">{{ role_seller_label }}</h3>
			
			<product-person-chooser-person-legacy v-for="person in sellers"
			                                      :uid="uid + '-' + person.id"
			                                      @update:modelValue="onPersonChange(person)"
			                                      @click="markPerson(person)"
			                                      :key="person.id"
			                                      :person="person"
			                                      :radio="sellers.length > 1"
			                                      :billing_address_label="billing_address_label"
			                                      :billing_postcode_label="billing_postcode_label"
			                                      :billing_city_label="billing_city_label"
			                                      :use_person_address_label="use_person_address_label"
			                                      :use_person_address_checked="use_person_address_checked"
			                                      :use_estate_address_label="use_estate_address_label"
			                                      :use_estate_address_checked="use_estate_address_checked"
			                                      :use_custom_address_label="use_custom_address_label"
			                                      :use_custom_address_checked="use_custom_address_checked"
			                                      :errors="errorsMapped.generic.concat(errorsMapped[person.id] || [])"
			                                      :has-current-errors="erroring"
			                                      :no-interact="noInteract"
			                                      :display-only="displayOnly"
			                                      :disabled="isDisabledForSellers" />
		</section>
		
		<!--
			Group responding to the "buyer" role (not type!)
		-->
		<section :class="['buyer']" v-if="role_buyer_label && (hasRequiredPersons || !no_persons_label)">
			<h3 v-if="shouldShowHeadings">{{ role_buyer_label }}</h3>
			
			<product-person-chooser-person-legacy v-for="person in buyers"
			                                      :uid="uid + '-' + person.id"
			                                      @update:modelValue="onPersonChange(person)"
			                                      @click="markPerson(person)"
			                                      :key="person.id"
			                                      :person="person"
			                                      :radio="buyers.length > 1"
			                                      :billing_address_label="billing_address_label"
			                                      :billing_postcode_label="billing_postcode_label"
			                                      :billing_city_label="billing_city_label"
			                                      :use_person_address_label="use_person_address_label"
			                                      :use_person_address_checked="use_person_address_checked"
			                                      :use_estate_address_label="use_estate_address_label"
			                                      :use_estate_address_checked="use_estate_address_checked"
			                                      :use_custom_address_label="use_custom_address_label"
			                                      :use_custom_address_checked="use_custom_address_checked"
			                                      :errors="errorsMapped.generic.concat(errorsMapped[person.id] || [])"
			                                      :has-current-errors="erroring"
			                                      :no-interact="noInteract"
			                                      :display-only="displayOnly"
			                                      :disabled="isDisabledForBuyers" />
		</section>
		
		<!--
			Message when not all required persons are registered
		-->
		<section class="no-persons" v-if="no_persons_label && !hasRequiredPersons">
			<acquisit-info-box type="warning" :label="no_persons_label" />
		</section>
		
		<footer tabindex="0" class="footer" v-if="billingFooterLabelFormatted && (hasRequiredPersons || !no_persons_label)" :aria-labelledby="uid + '-footer'">
			<acquisit-string :source="billingFooterLabelFormatted" :id="uid + '-footer'" />
		</footer>
	</div>
</template>

<script setup lang="ts">
	import ProductPersonChooserPersonLegacy from '@ui/person/ProductPersonChooserPersonLegacy.vue'
	import { usePersonsStore } from '@/stores/persons'
	import { useBaseComponentProps, useBaseComponentEmits, useBaseComponent } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import type { Person, ValidationError } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { computed } from 'vue'
	
	const props = defineProps({
		...useBaseComponentProps(),
		
		/**
		 * Label to show above the chooser as a header
		 */
		header_label: {
			type: String,
			required: false,
			default: null
		},
		
		/**
		 * Label to use for the group responding to sellers
		 */
		role_seller_label: {
			type: String,
			required: false,
			default: null
		},
		
		/**
		 * Label to use for the group responding to buyers
		 */
		role_buyer_label: {
			type: String,
			required: false,
			default: null
		},
		
		billing_address_label: {
			type: String,
			required: false,
			default: null
		},
		
		billing_postcode_label: {
			type: String,
			required: false,
			default: null
		},
		
		billing_city_label: {
			type: String,
			required: false,
			default: null
		},
		
		billing_footer_label: {
			type: String,
			required: false,
			default: null
		},
		
		/*
		 * Choices of addresses
		 */
		
		use_person_address_label: {
			type: String,
			required: false,
			default: null
		},
		
		use_person_address_checked: {
			type: Boolean,
			required: false,
			default: false
		},
		
		use_estate_address_label: {
			type: String,
			required: false,
			default: null
		},
		
		use_estate_address_checked: {
			type: Boolean,
			required: false,
			default: false
		},
		
		use_custom_address_label: {
			type: String,
			required: false,
			default: null
		},
		
		use_custom_address_checked: {
			type: Boolean,
			required: false,
			default: false
		},
		
		no_persons_label: {
			type: String,
			required: false,
			default: null
		}
	})
	
	const emit = defineEmits([
		...useBaseComponentEmits(),
		'update:modelValue'
	])
	
	const base = useBaseComponent(props, emit)
	const { erroring } = base
	
	const personsStore = usePersonsStore()
	
	const markPerson = (person: Person) => {
		if (props.noInteract) {
			return false
		}
		
		const otherPersons = usePersonsStore().list.filter(p => p.id !== person.id && person.role == p.role)
		
		for (let person of otherPersons) {
			personsStore.unmark(person)
		}
		
		personsStore.mark(person)
		emitValue()
	}
	
	const onPersonChange = (person: Person) => {
		if (props.noInteract) {
			return false
		}
		
		personsStore.update(person)
		emitValue()
	}
	
	const emitValue = () => {
		// Emit a static value so validation works
		emit('update:modelValue', 1)
	}
	
	const buyers = computed(() => personsStore.buyers)
	const sellers = computed(() => personsStore.sellers)
	
	/**
	 * Get the currently marked seller
	 */
	const markedSeller = computed(() => sellers.value.filter(p => p.marked)[0] ?? undefined)
	/**
	 * Get the currently marked buyer
	 */
	const markedBuyer = computed(() => buyers.value.filter(p => p.marked)[0] ?? undefined)
	/**
	 * Whether to show headings
	 * true if both headings are set
	 */
	const shouldShowHeadings = computed(() => props.role_seller_label && props.role_buyer_label)
	/**
	 * Whether the buyers group can even mark someone
	 */
	const isDisabledForBuyers = computed(() => buyers.value.length <= 1)
	/**
	 * Whether the sellers group can even mark someone
	 */
	const isDisabledForSellers = computed(() => sellers.value.length <= 1)
	
	const hasRequiredPersons = computed(() => {
		if (props.role_seller_label && !sellers.value.length) {
			return false
		}
		
		return !props.role_buyer_label && !buyers.value.length
	})
	
	const billingFooterLabelFormatted = computed(() => {
		if (!props.billing_footer_label) {
			return undefined
		}
		
		let footer = String(props.billing_footer_label)
		
		if (footer.match(/{buyer\..*?}/)) {
			let person = markedBuyer.value
			
			if (!person) {
				return null
			}
			
			footer = footer.replace(/{buyer\.name}/g, `**${[person.firstname, person.name].filter(x => !!x).join(' ')}**`)
		}
		
		if (footer.match(/{seller\..*?}/)) {
			let person = markedSeller.value
			
			if (!person) {
				return null
			}
			
			footer = footer.replace(/{seller\.name}/g, `**${[person.firstname, person.name].filter(x => !!x).join(' ')}**`)
		}
		
		return footer
	})
	
	const errorsMapped = computed(() => {
		let map = {
			generic: [] as ValidationError[]
		}
		
		if (!props.errors || !Array.isArray(props.errors)) {
			return map
		}
		
		for (let error of props.errors) {
			if (error.owner?.person?.id) {
				let person = error.owner.person
				
				if (!map[person.id]) {
					map[person.id] = []
				}
				
				map[person.id].push(error)
			} else {
				map.generic.push(error)
			}
		}
		
		return map
	})
	
	defineExpose({
		...base.expose
	})
</script>

<style lang="scss" scoped>
	@use '@/assets/mixins.scss' as m;
	
	.acquisit-header + .acquisit-legacy-product-person-chooser {
		margin-top: 8px;
	}
	
	.acquisit-legacy-product-person-chooser {
		
		.header {
			margin-bottom: 8px;
		}
		
		section {
			
			h3 {
				font-size: 1.06rem;
				font-weight: 600;
				
				padding-bottom: 16px;
			}
			
			& + section {
				margin-top: 36px;
			}
			
			&.error {
				
				h3 {
					color: m.color("red");
					border-color: m.color("red");
				}
				
				.person {
					border-color: m.color("red");
				}
			}
		}
		
		.footer {
			margin-top: 24px;
			text-align: center;
			
			.acquisit-string :deep(strong) {
				font-size: 1.067rem;
			}
		}
	}
</style>
