import type { Client, Office, OrderResponse, UIResponse } from '@/lib/backend/types'
import { isUIResponseV2, isUIResponseV3 } from '@/lib/backend/types'
import type { Address, Component, ComponentValueAvailableExport, ComponentValueAvailableImport, DataStore, ExtractedValues, Intro, ModalCompound, MultiExportContext, Outro, Page, PagesStore, Product, ProductUIDDictionary, UID, UILabelOptional } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { createDefaultDataStore, DateTimeExt, DisplayOfficesMode, exportFromComponents, extractImagesInputImages, ExtractionVersion, extractValuesFromComponents, labelFor, importToComponents, isPage, isProduct, useLogger, useRaygun } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import type { EvaluationContext, ExtractedUIDs } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/expressions'
import { evaluateCondition, extractUIDs, isCondition, isConditionGroup } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/expressions'
import type { Backup } from '@/stores/backup'
import { useBackup } from '@/stores/backup'
import { defineStore } from 'pinia'
import { cast, clone, collectByUID, componentHasSemantic, createRecord, find, findDuplicates, findWithSemantic, isset, merge, mergeSets, normalizeComponents, normalizePage, pageHasSemantic, uniqueArray } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import { applyTagRenames, cleanComponentsForBackup, isUIDReserved, useComponentsStore } from '@/stores/components'
import { usePersonsStore } from '@/stores/persons'
import { useBackend } from '@/lib/backend'
import { useAPIStore } from '@/stores/api'
import { ResponseType } from '@/lib/backend/responses'
import { useBrandingStore } from '@/stores/branding'
import { useProductsStore } from '@/stores/products'
import { Origin, useGenericStore } from '@/stores/generic'
import { usePageStore } from '@/stores/page'
import { useScreenshotsStore } from '@/stores/screenshots'
import { normalizePages } from '@/lib/functions/page'
import { computed, toRef } from 'vue'
import { useLogsStore } from '@/stores/log'

export const SEMANTIC_PRODUCT_PERSONS = 'product_persons'
export const SEMANTIC_METERS = 'meters'
export const SEMANTIC_ELECTRICITY = 'electricity'
export const SEMANTIC_METER_NUMBER = 'meter-number'
export const SEMANTIC_METER_MILEAGE = 'meter-mileage'
export const SEMANTIC_METER_READING_DATE = 'meter-reading-date'
export const SEMANTIC_METER_IMAGES = 'meter-images'

const log = useLogger('stores/protocol', useLogger.COLOR_STORE)

export type DependenciesSources = {
	[uid: string]: ExtractedUIDs
}

export interface DependenciesTargets {
	components: { [uid: string]: Set<UID> }
	pages: { [uid: string]: Set<UID> }
	products: { [uid: string]: Set<UID> }
}

export interface ProtocolStoreState {
	// Current client
	client: Client|null|undefined
	// Office the estate belongs to, if any
	office: Office|null|undefined
	// Current order
	order: Omit<OrderResponse, 'client'|'office'>|null|undefined
	// Current Ticket ID
	ticket_id: string|number|null|undefined
	// The type of task, CRM-specific value
	task_type: string|null|undefined
	// The type of property, CRM-specific value
	property_type: string|null|undefined
	// The takeover date as a UNIX timestamp (seconds)
	takeover_date: number|null|undefined
	// Start date as a UNIX timestamp (seconds)
	start_date: number|null|undefined
	// Intro
	intro: Intro|null|undefined
	// Outro
	outro: Outro|null|undefined
	// All available pages
	pages: Page[]
	// Dependencies between components and pages
	components_dependencies: {
		sources: DependenciesSources,
		targets: DependenciesTargets
	} | null
	pages_dependencies: {
		sources: DependenciesSources,
		targets: DependenciesTargets
	} | null
	// Layout style
	style: string|null|undefined
	// Available languages
	available_languages: string[]
	// Human language
	language: string|null|undefined
	// Validate (Next) page label
	validate_page_label: UILabelOptional
	// Back button label
	back_button_label: UILabelOptional
	// Menu button label
	menu_button_label: UILabelOptional
	// Jump to content label (for users tabbing)
	jump_to_content_label: UILabelOptional
	// Reset protocol label
	reset_protocol_label: UILabelOptional
	// Reset protocol modal
	reset_protocol_modal: ModalCompound|null|undefined
	// Additional information modal label
	additional_information_label: UILabelOptional
	// Additional information actual modal
	additional_information_modal: ModalCompound|null|undefined
	// Whether to create a PDF summary
	create_pages_pdf: boolean
	// Whether the copyright notice is visible
	copyright_visible: boolean
	// Data store for syncing with AcquisitUI
	acquisitUIDataStore: DataStore
	// Whether the protocol has been backed up at least once
	backed_up: boolean
	imports: ComponentValueAvailableImport[]
	exports: ComponentValueAvailableExport[]
}

export interface ProtocolSubmission {
	_version: string
	_host: string
	_build: number
	
	order: {
		type: string|null
		external_id: string|number
	}
	
	products: any[]
	images?: Record<string, any>
	items?: Record<string, any>
	meters?: Record<string, any>
	exports?: Record<string, any>
	components: Record<string, any>
	protocol_pdf?: string
	persons: any[]
	ticket_id: string
	started_at: number
	payload_created_at: number
	payload_submitted_at: number
	user_agent: string|null
	client_short: string|null
}

const validateOrderResponse = (data: Record<string, any>) => {
	if (typeof(data) !== 'object' || data === null || Array.isArray(data)) {
		throw new Error('Empty order received')
	}
	
	if (!data.token) {
		throw new Error('Order token missing');
	}
	
	if (!data.branding?.color?.primary || !data.branding?.color?.secondary || !data.branding?.logo?.primary || !data.branding?.logo?.secondary) {
		throw new Error('Branding missing or incomplete')
	}
	
	return true
}

export const validateUIResponse = (data: Record<string, any>) => {
	if (typeof(data) !== 'object' || data === null || Array.isArray(data)) {
		throw new Error('Empty UI received')
	}
	
	if (!Array.isArray(data.pages)) {
		throw new Error('Pages missing')
	}
	
	return true
}

const getMeterSemanticForComponent = (component: Component): string|null => {
	if (component.tag_normalized != 'items-input' || !componentHasSemantic(component, [ SEMANTIC_METERS ])) {
		return null
	}
	
	if (componentHasSemantic(component, [ SEMANTIC_ELECTRICITY ])) {
		return SEMANTIC_ELECTRICITY
	}
	
	return null
}

const extractMetersFromPages = (pages: Page[]) => {
	let meters: Record<string, ExtractedValues[]> = {}
	
	for (let page of pages) {
		for (let component of page.components) {
			if (componentHasSemantic(component, [ SEMANTIC_METERS ]) && component.tag_normalized == 'items-input') {
				const semantic = getMeterSemanticForComponent(component)
				
				if (semantic) {
					const extracted = extractMetersFromItemsInput(component)
					
					if (!meters[semantic]) {
						meters[semantic] = []
					}
					
					meters[semantic].push(extracted)
				}
			}
		}
	}
	
	return meters
}

/**
 * Extract meters from an ItemsInput
 */
const extractMetersFromItemsInput = (component: Component): ExtractedValues => {
	let meters: ExtractedValues[] = []
	
	for (let item of component.properties.modelValue ?? []) {
		const meterMileageComponent = clone(findWithSemantic<Component>(item.components, [ SEMANTIC_METER_MILEAGE ]))
		const meterNumberComponent = clone(findWithSemantic<Component>(item.components, [ SEMANTIC_METER_NUMBER ]))
		const meterReadingDateComponent = clone(findWithSemantic<Component>(item.components, [ SEMANTIC_METER_READING_DATE ]))
		const meterImagesComponent = clone(findWithSemantic<Component>(item.components, [ SEMANTIC_METER_IMAGES ]))
		
		if (meterMileageComponent) {
			meterMileageComponent.properties.uid = 'mileage'
		}
		
		if (meterNumberComponent) {
			meterNumberComponent.properties.uid = 'number'
		}
		
		if (meterReadingDateComponent) {
			meterReadingDateComponent.properties.uid = 'reading_date'
		}
		
		if (meterImagesComponent) {
			meterImagesComponent.properties.uid = 'images'
		}
		
		const meterComponents = [
			meterMileageComponent,
			meterNumberComponent,
			meterReadingDateComponent
		].filter(x => !!x) as Component[]
		
		const values = extractValuesFromComponents({
			componentsByUID: collectByUID(meterComponents),
			productsByUID: {},
			personsByID: {}
		})
		
		if (meterImagesComponent) {
			const images = extractImagesInputImages([ meterImagesComponent ], false, ExtractionVersion.V3_0)
			values.images = merge(values.images ?? {}, images)
		}
		
		meters.push(values)
	}
	
	return meters
}

const linkProductsForComponents = (components: Component[], productsByUID: ProductUIDDictionary) => {
	for (let component of components) {
		switch (component.tag_normalized) {
			case 'single-product-chooser-carousel':
			case 'single-product-chooser-list':
				const products = component.properties.products ?? []
				linkProducts(products, productsByUID)
				
				break
		}
	}
}

const linkProducts = (target: Product[], originals: ProductUIDDictionary) => {
	for (let i = 0, max = target.length; i < max; i++) {
		const oldProduct = target[i]
		
		if (originals[oldProduct.uid]) {
			target[i] = originals[oldProduct.uid]
		}
	}
}

export const useProtocolStore = defineStore({
	id: 'protocol',
	
	state: () => cast<ProtocolStoreState>({
		additional_information_label: undefined,
		additional_information_modal: undefined,
		back_button_label: undefined,
		client: undefined,
		office: undefined,
		task_type: undefined,
		property_type: undefined,
		takeover_date: undefined,
		components_dependencies: null,
		pages_dependencies: null,
		create_pages_pdf: false,
		intro: undefined,
		menu_button_label: undefined,
		jump_to_content_label: undefined,
		available_languages: [],
		language: undefined,
		order: undefined,
		outro: undefined,
		pages: [],
		reset_protocol_label: undefined,
		reset_protocol_modal: undefined,
		start_date: undefined,
		style: undefined,
		ticket_id: undefined,
		validate_page_label: undefined,
		copyright_visible: true,
		backed_up: false,
		acquisitUIDataStore: createDefaultDataStore(),
		imports: [],
		exports: []
	}),
	
	getters: {
		page_names_by_uid: state => createRecord(state.pages, page => page.data.uid, page => page.data.name),
		pages_by_uid: state => createRecord(state.pages, page => page.data.uid),
		
		enabled_pages: state => state.pages?.filter(p => p.enabled) || [],
		disabled_pages: state => state.pages?.filter(p => !p.enabled) || [],
		
		pagesWithSemantic: state => (semantic: string|string[]) => {
			let pages: Page[] = []
			
			for (let page of state.pages) {
				if (pageHasSemantic(page, semantic)) {
					pages.push(page)
				}
			}
			
			return pages
		},
		
		pagesWithComponentOfTag: state => (normalizedTag: string) => {
			let pages: Page[] = []
			
			let hasComponent = (componentsContainer: Component[]) => {
				for (let component of componentsContainer) {
					if (component.tag_normalized == normalizedTag) {
						return true
					}
					
					for (let slot in component.children) {
						if (component.children.hasOwnProperty(slot) && hasComponent(component.children[slot])) {
							return true
						}
					}
				}
				
				return false
			}
			
			for (let page of state.pages) {
				if (hasComponent(page.components)) {
					pages.push(page)
				}
			}
			
			return pages
		},
		
		pagesWithComponentOfUID: state => (uid: UID) => {
			for (let page of state.pages) {
				if (find(page.components, uid)) {
					return page
				}
			}
			
			return null
		},
		
		pagesWithProductOfUID: state => (uid: UID) => {
			const findComponentsWithProducts = (componentsContainer: Component[]) => {
				let components: Component[] = []
				
				for (let component of componentsContainer) {
					if (component.properties.products) {
						components.push(component)
					}
					
					if (component.children?.default) {
						components = components.concat(findComponentsWithProducts(component.children.default))
					}
				}
				
				return components
			}
			
			const pages: Page[] = []
			
			for (let page of state.pages) {
				const components = findComponentsWithProducts(page.components)
				
				for (let component of components) {
					for (let product of component.properties.products) {
						if (product.uid == uid) {
							pages.push(page)
						}
					}
				}
			}
			
			return pages
		},
		
		currentLanguageLabelFor: state => (label: UILabelOptional): string|null|undefined => {
			return labelFor(label, state.language)
		},
		
		/**
		 * Get all component UIDs that depend on component with uid
		 */
		dependenciesForComponentOfUID: state => (uid: UID) => state.components_dependencies?.targets?.components[uid] || null,
		
		/**
		 * Get all component and page UIDs that depend on page with uid
		 */
		dependenciesForPageOfUID: state => (uid: UID) => mergeSets<UID>(
			state.components_dependencies?.targets?.pages[uid],
			state.pages_dependencies?.targets?.pages[uid]
		),
		
		/**
		 * Get all component UIDs that depend on product with UID
		 */
		dependenciesForProductOfUID: state => (uid: UID) => state.components_dependencies?.targets?.products[uid] || null,
		
		/**
		 * Get all dependencies that a component with UID depends on
		 */
		dependenciesOfComponentOfUID: state => (uid: UID) => state.components_dependencies?.sources?.[uid] || null,
		
		backup: state => {
			let backup = clone((state as ProtocolStoreState & { $state: ProtocolStoreState }).$state) as any
			
			for (let page of backup.pages) {
				page.data.errors = []
				cleanComponentsForBackup(page.components)
			}
			
			delete backup.components_dependencies
			delete backup.pages_dependencies
			
			return backup
		}
	},
	
	actions: {
		applyOrderResponse(data: OrderResponse) {
			const apiStore = useAPIStore(),
				brandingStore = useBrandingStore(),
				personsStore = usePersonsStore()
			
			apiStore.setProtocolToken(data.token)
			brandingStore.set(data.branding)
			personsStore.set(data.persons)
			
			this.client = data.client
			this.office = data.office
			this.order = data
			this.ticket_id = data.ticket_id
			this.task_type = data.task_type ?? undefined
			this.property_type = data.property_type ?? undefined
			this.takeover_date = data.takeover_date ?? undefined
			
			// Backwards-compatibility with Acquisit 3.x
			if (data.intro) {
				this.intro = data.intro
			}
			
			if (data.outro) {
				this.outro = data.outro
			}
			
			// Remove duplicate data
			delete (<any> this.order).office
			delete (<any> this.order).client
			
			this.acquisitUIDataStore.estateAddress.address = data.address.address
			this.acquisitUIDataStore.estateAddress.postcode = data.address.postcode
			this.acquisitUIDataStore.estateAddress.city = data.address.city
			
			if (Array.isArray(data.imports)) {
				this.imports = data.imports
			}
			
			if (Array.isArray(data.exports)) {
				this.exports = data.exports
			}
			
			if (this.outro?.components) {
				normalizeComponents(this.outro.components)
			}
		},
		
		/**
		 * Fetch a protocol's data with a ticket and set it accordingly
		 */
		async fetchDataWithTicket(ticketID: string, password: string): Promise<OrderResponse> {
			try {
				const backend = useBackend()
				const data = await backend.retry(() => backend.getOrderByTicket(ticketID, password), 3)
				
				validateOrderResponse(data)
				this.applyOrderResponse(data)
				
				return data
			} catch (e: any) {
				if (e.type && e.type == ResponseType.NoResponseError) {
					e.message = "fetchDataWithTicket received no data"
				}
				
				throw e
			}
		},
		
		/**
		 * Fetch a protocol's data with a token and set it accordingly
		 */
		async fetchDataWithToken(token: string): Promise<OrderResponse> {
			try {
				const backend = useBackend()
				const data = await backend.retry(() => backend.getOrderByToken(token), 3)
				
				validateOrderResponse(data)
				this.applyOrderResponse(data)
				
				return data
			} catch (e: any) {
				if (e.type && e.type == ResponseType.NoResponseError) {
					e.message = "fetchDataWithToken received no data"
				}
				
				throw e
			}
		},
		
		async fetchUI(token: string): Promise<UIResponse> {
			try {
				const backend = useBackend()
				
				const data = await backend.getUI(token)
				validateUIResponse(data)
				
				// @ts-ignore On Pylon this can be empty nonetheless
				if (data == '') {
					throw {
						type: ResponseType.UnknownError,
						message: 'The returned UI is empty'
					}
				}
				
				const genericStore = useGenericStore()
				
				// Normalize page data
				normalizePages(data.pages)
				
				// Basics
				this.setPages(data.pages)
				this.style = data.style
				
				// Backwards-compatibility with older responses
				if (isUIResponseV2(data)) {
					// Languages
					this.language = data.language || null
					this.available_languages = data.available_languages ? data.available_languages : data.language ? [ data.language ] : []
					
					// Button labels
					this.back_button_label = data.back_button_label
					this.validate_page_label = data.validate_page_label
					this.jump_to_content_label = data.jump_to_content_label || undefined
					this.menu_button_label = data.menu_button_label || undefined
					
					// Reset UI
					this.reset_protocol_label = data.reset_protocol_label || undefined
					this.reset_protocol_modal = data.reset_protocol_modal || undefined
					
					// Additional Info UI
					this.additional_information_label = data.additional_information_label || undefined
					this.additional_information_modal = data.additional_information_modal || undefined
					
					genericStore.setOrigin(Origin.CLASSIC)
				} else if (isUIResponseV3(data)) {
					// Intro, Outro
					this.intro = data.intro
					this.outro = data.outro
					
					// Languages
					this.language = data.default_language ?? null
					this.available_languages = data.available_languages ?? []
					
					// Button labels
					this.back_button_label = data.labels.back_button
					this.validate_page_label = data.labels.validate_page
					this.jump_to_content_label = data.labels.jump_to_content
					this.menu_button_label = data.labels.menu_button
					
					// Reset UI
					this.reset_protocol_label = data.reset_protocol?.label ?? undefined
					this.reset_protocol_modal = data.reset_protocol?.modal ?? undefined
					
					// Additional info UI
					this.additional_information_label = data.additional_information?.label ?? undefined
					this.additional_information_modal = data.additional_information?.modal ?? undefined
					
					if (isset((<any> data)._portal_build)) {
						genericStore.setOrigin(Origin.UI_BUILDER)
					} else {
						genericStore.setOrigin(Origin.CLASSIC)
					}
				} else {
					throw new Error('Invalid UI response')
				}
				
				// Features
				this.create_pages_pdf = data.create_pages_pdf || false
				
				// Copyright notice
				this.copyright_visible = data.copyright_visible ?? true
				
				return data
			} catch (e: any) {
				if (e.type && e.type == ResponseType.NoResponseError) {
					e.message = "fetchUI received no data"
				}
				
				throw e
			}
		},
		
		async storeBackup(token: string): Promise<void> {
			const backupHelper = useBackup(),
				backend = useBackend()
			
			await backend.storeBackup(token, backupHelper.create())
			this.backed_up = true
		},
		
		restoreBackup(backup: Record<string, any>, rootBackup: Backup) {
			if (!backup.client || !backup.order || !backup.intro) {
				// Probably not an acquisit backup
				throw new Error('Invalid backup')
			}
			
			this.client = backup.client
			this.office = backup.office || backup.order.office // backwards-compatibility with older Acquisit
			this.order = backup.order
			this.ticket_id = backup.ticket_id
			this.start_date = backup.start_date
			this.intro = backup.intro
			this.outro = backup.outro
			this.create_pages_pdf = backup.create_pages_pdf
			this.reset_protocol_label = backup.reset_protocol_label
			this.reset_protocol_modal = backup.reset_protocol_modal
			this.additional_information_label = backup.additional_information_label
			this.additional_information_modal = backup.additional_information_modal
			this.style = backup.style
			this.validate_page_label = backup.validate_page_label
			this.menu_button_label = backup.menu_button_label
			this.back_button_label = backup.back_button_label
			this.jump_to_content_label = backup.jump_to_content_label
			this.available_languages = backup.available_languages ? backup.available_languages : backup.language ? [ backup.language ] : []
			this.language = backup.language
			this.copyright_visible = backup.copyright_visible ?? true
			this.imports = backup.order.imports ?? []
			this.exports = backup.order.exports ?? []
			this.backed_up = true
			
			let pages: Page[] = backup.pages,
				currentPage: Page|null = null
			
			const productDictionary = useProductsStore().by_uid
			
			for (let page of pages) {
				normalizePage(page)
				normalizeComponents(page.components)
				applyTagRenames(page.components)
				linkProductsForComponents(page.components, productDictionary)
				
				if (page.data?.uid == rootBackup.page?.current_page?.data?.uid) {
					currentPage = page
				}
			}
			
			this.pages = pages
			
			this.evaluatePageDisplayConditions(currentPage)
			this.buildComponentDependencies()
			this.buildPageDependencies()
			
			usePageStore().setCurrentPage(currentPage || pages[0])
		},
		
		buildComponentDependencies() {
			const componentsStore = useComponentsStore(),
				productsStore = useProductsStore(),
				personsStore = usePersonsStore()
			
			const context = {
				componentsByUID: componentsStore.by_uid,
				pagesByUID: this.pages_by_uid,
				productsByUID: productsStore.by_uid,
				personsByID: personsStore.persons,
				scope: {}
			}
			
			// Key is UID of component, value is an object with components, pages and products that component depends on
			// Source means meaning components that have a dependency on another thing
			let sourceDependenciesOfComponents: DependenciesSources = {}

			for (let uid in context.componentsByUID) {
				if (context.componentsByUID.hasOwnProperty(uid)) {
					let component = context.componentsByUID[uid],
						dependencies: ExtractedUIDs & { host_page?: Page } = {
							components: new Set(),
							pages: new Set(),
							products: new Set()
						}

					// Check display conditions
					if (component.display_condition) {
						// Extract UIDs of what the condition depends on
						dependencies = extractUIDs(component.display_condition, context)
					}

					// Check any persons conditions
					// Either a condition directly (valid for all persons) or an object
					// whose key is the person type and value the condition
					if (component.properties.persons_conditions) {
						let conditions = component.properties.persons_conditions

						if (isCondition(conditions) || isConditionGroup(conditions)) {
							// Add directly
							let additional = extractUIDs(component.properties.persons_conditions, context)
							dependencies = merge(dependencies, additional)
						} else {
							// Is an object with key being the type of persons this is valid for
							for (let type in conditions) {
								if (conditions.hasOwnProperty(type)) {
									if (!conditions[type]) {
										continue
									}
									
									let additional = extractUIDs(conditions[type], context)
									dependencies = merge(dependencies, additional)
								}
							}
						}
					}

					// If it has dependencies on other components, find the pages those components belong to
					if (dependencies?.components.size) {
						let pageOfComponent = this.pagesWithComponentOfUID(component.properties.uid)

						if (pageOfComponent) {
							if (dependencies) {
								dependencies.host_page = pageOfComponent
							} else {
								dependencies = {
									components: new Set(),
									pages: new Set(),
									products: new Set(),
									host_page: pageOfComponent
								}
							}
						}
					}

					if (dependencies) {
						sourceDependenciesOfComponents[component.properties.uid] = dependencies
					}
				}
			}

			this.components_dependencies = {
				sources: sourceDependenciesOfComponents,
				targets: this.buildTargetDependencies(sourceDependenciesOfComponents)
			}
		},
		
		buildPageDependencies() {
			const componentsStore = useComponentsStore(),
				productsStore = useProductsStore(),
				personsStore = usePersonsStore()
			
			const context: EvaluationContext = {
				componentsByUID: componentsStore.by_uid,
				pagesByUID: this.pages_by_uid,
				productsByUID: productsStore.by_uid,
				personsByID: personsStore.persons,
				scope: {}
			}
			
			let sourceDependenciesOfPages: Record<string, ExtractedUIDs> = {}
			
			for (let uid in context.pagesByUID) {
				if (context.pagesByUID.hasOwnProperty(uid)) {
					let page = context.pagesByUID[uid],
						dependencies: ExtractedUIDs = {
							components: new Set(),
							pages: new Set(),
							products: new Set(),
						}
					
					if (page.display_condition) {
						dependencies = extractUIDs(page.display_condition, context)
					}
					
					if (dependencies.components.size) {
						// Find host pages for the components and add them as a dependency
						for (let componentUID of dependencies.components) {
							const hostPage = this.pagesWithComponentOfUID(componentUID)
							
							if (hostPage) {
								dependencies.pages.add(hostPage.data.uid)
							}
						}
					}
					
					if (dependencies.products.size) {
						for (let productUID of dependencies.products) {
							// Find page containing the product and add it
							const pages = this.pagesWithProductOfUID(productUID)
							dependencies.pages = mergeSets(dependencies.pages, new Set(pages.map(p => p.data.uid)))
						}
					}
					
					sourceDependenciesOfPages[page.data.uid] = dependencies
				}
			}
			
			this.pages_dependencies = {
				sources: sourceDependenciesOfPages,
				targets: this.buildTargetDependencies(sourceDependenciesOfPages)
			}
		},
		
		/**
		 * Given a directory of source dependencies (UID -> DependenciesSources), figure out the other way round
		 * Target meaning things that have been targeted by a source (the source has a condition/dependency on this one)
		 *
		 * @param sourceDependencies
		 * @returns {DependenciesTargets}
		 */
		buildTargetDependencies(sourceDependencies: Record<string, ExtractedUIDs>): DependenciesTargets {
			let targetDependencies: DependenciesTargets = {
				components: {},
				pages: {},
				products: {}
			}

			for (let uid in sourceDependencies) {
				if (sourceDependencies.hasOwnProperty(uid)) {
					let deps = sourceDependencies[uid]

					for (let targetComponentUID of deps.components) {
						if (!targetDependencies.components[targetComponentUID]) {
							targetDependencies.components[targetComponentUID] = new Set()
						}

						targetDependencies.components[targetComponentUID].add(uid)
					}

					for (let targetPageUID of deps.pages) {
						if (!targetDependencies.pages[targetPageUID]) {
							targetDependencies.pages[targetPageUID] = new Set()
						}

						targetDependencies.pages[targetPageUID].add(uid)
					}

					for (let targetProductUID of deps.products) {
						if (!targetDependencies.products[targetProductUID]) {
							targetDependencies.products[targetProductUID] = new Set()
						}

						targetDependencies.products[targetProductUID].add(uid)
					}
				}
			}
			
			return targetDependencies
		},
		
		setStartDate(timestamp?: number|null) {
			if (timestamp === undefined) {
				this.start_date = (+new Date()) / 1000
			} else {
				this.start_date = timestamp
			}
		},
		
		setPages(pages: Page[]) {
			pages = clone(pages)
			
			// Extracted components as a big list for easier usage later on
			let components: Component[] = []
			
			// Extracted products
			let products: Product[] = []
			
			// Also check for duplicate keys
			let keys = {
				components: [] as string[],
				pages: [] as string[]
			}
			
			let findAllComponents = (component: Component) => {
				if (!component.tag) {
					log.always.errorFrom('setPages', 'Not a component:', component)
					
					return {
						components: [],
						products: []
					}
				}
				
				let components: Component[] = [ component ]
				let products: Product[] = []
				
				if (Array.isArray(component.properties.products)) {
					products = component.properties.products.map(p => {
						p.origin_component_uid = component.properties.uid
						return p
					})
				}
				
				if (component.tag_normalized == 'checkbox' && isProduct(component.properties.product)) {
					const product = component.properties.product
					product.origin_component_uid = component.properties.uid
					
					products.push(product)
				}
				
				if (component.children) {
					if (Array.isArray(component.children)) {
						// non-slotted
						for (let child of component.children) {
							let res = findAllComponents(child)
							components = components.concat(res.components)
							products = products.concat(res.products)
						}
					} else if (Array.isArray(component.children.default)) {
						for (let child of component.children.default) {
							let res = findAllComponents(child)
							components = components.concat(res.components)
							products = products.concat(res.products)
						}
					}
				}
				
				return {
					components,
					products
				}
			}
			
			let i = 0
			
			for (let page of pages) {
				if (!page) {
					throw new Error('Index ' + i + ' is not a page: ' + JSON.stringify(page))
				}
				
				if (!page.data) {
					throw new Error('Index ' + i + ' is missing data')
				}
				
				normalizePage(page)
				normalizeComponents(page.components)
				applyTagRenames(page.components)
				
				// Backwards compatibility
				if (page.enabled === undefined && page.data.disabled) {
					page.enabled = !page.data.disabled
				} else {
					page.enabled = page.enabled || true
					page.data.disabled = page.data.disabled || false
				}
				
				page.data.validated = page.data.validated || false
				page.data.errors = page.data.errors || null
				
				if (!Array.isArray(page.components)) {
					throw new Error('Index ' + i + ' (' + page.data.uid + ') is missing components')
				}
				
				keys.pages.push(page.data.uid)
				
				for (let component of page.components) {
					if (Array.isArray(component)) {
						// Incorrect formatting
						throw new Error('Expected component, got Array at index #' + i + ' on page "' + page.data.uid + '"')
					}
					
					// Add component + children to components list
					let newComponents = findAllComponents(component)
					
					for (let c of newComponents.components) {
						// Save key for duplicate detection
						keys.components.push(c.properties.uid)
						
						if (isUIDReserved(c.properties.uid)) {
							throw new Error('UID "' + c.properties.uid + '" is reserved');
						}
					}
					
					components = components.concat(newComponents.components)
					products = products.concat(newComponents.products)
				}
				
				i++
			}
			
			let duplicates = {
				components: uniqueArray(findDuplicates(keys.components)),
				pages: uniqueArray(findDuplicates(keys.pages))
			}
			
			if (duplicates.pages.length) {
				throw new Error('Duplicate page UIDs: ' + duplicates.pages.join(', '))
			}
			
			if (duplicates.components.length) {
				throw new Error('Duplicate component UIDs: ' + duplicates.components.join(', '))
			}
			
			useProductsStore().set(products)
			
			// Set!
			this.pages = pages
			
			this.evaluatePageDisplayConditions(null)
			this.buildComponentDependencies()
			this.buildPageDependencies()
		},
		
		/**
		 * Hydrate all components importing values
		 * Requires this.imports to be set beforehand
		 */
		async hydrateWithImports() {
			// Hydrate with imports
			const availableImportsByUID = createRecord(this.imports, i => i.uid),
				personsByID = usePersonsStore().by_id
			
			for (let page of this.pages) {
				await importToComponents(page.components, {
					availableByUID: availableImportsByUID,
					personsByID
				})
			}
		},
		
		async createSubmissionPayload(): Promise<ProtocolSubmission|null> {
			if (!this.order || !this.client) {
				return null
			}
			
			const componentsStore = useComponentsStore(),
				personsStore = usePersonsStore(),
				genericStore = useGenericStore(),
				productsStore = useProductsStore()
			
			const extractionVersion = genericStore.origin == Origin.UI_BUILDER ? ExtractionVersion.V3_0 : ExtractionVersion.V2_1
			
			let { values, images, items } = extractValuesFromComponents({
				componentsByUID: componentsStore.by_uid,
				productsByUID: productsStore.by_uid,
				personsByID: personsStore.by_id
			}, genericStore.dev.submission_payload_validated_only, extractionVersion)
			
			let copyAddress = (source: Address, target: Address) => {
				target.address = source.address
				target.city = source.city
				target.postcode = source.postcode
			}
			
			let persons = personsStore.submission_list.map(person => {
				const copy = clone(person) as Record<string, any>
				
				delete copy.signature_type
				delete copy.index
				delete copy.selected
				
				if (copy.address) {
					delete copy.address.copied
				}
				
				// FIXME: Remove 2 weeks after launch of new product person chooser
				if (copy.billing_address && !copy.billing_address.use_custom_address) {
					if (copy.billing_address.use_estate_address) {
						copyAddress(this.order!.address, copy.billing_address)
					} else if (copy.billing_address.use_person_address) {
						copyAddress(copy.address, copy.billing_address)
					}
				}
				
				// Remove any additional components (these are internal state)
				delete copy.additional_components
				
				return copy
			})
			
			let personsByID = createRecord(persons, p => p.id)
			
			// Apply ProductPersonChooser addresses
			let productPersonChoosers = componentsStore.by_tag['product-person-chooser'] || []
			
			for (let chooser of productPersonChoosers) {
				if (chooser.properties.modelValue) {
					let value = chooser.properties.modelValue
					
					// Apply default chooser address to persons
					if (chooser.properties.is_default) {
						// Find selected persons
						let roles = {
							role_seller_label: 'selected_seller_id',
							role_buyer_label: 'selected_buyer_id',
						}
						
						for (let propKey of Object.keys(roles)) {
							if (chooser.properties[propKey]) {
								let selectedPersonKey = roles[propKey],
									person = personsByID[value[selectedPersonKey]]
								
								if (person) {
									person.marked = true
									
									if (value.billing_addresses) {
										let billingAddress = clone(value.billing_addresses[person.id])
										
										if (!billingAddress.use_custom_address) {
											if (billingAddress.use_estate_address) {
												copyAddress(this.order.address, billingAddress)
											} else if (billingAddress.use_person_address) {
												copyAddress(person.address, billingAddress)
											}
										}
										
										person.billing_address = billingAddress
									}
								}
							}
						}
					}
				}
			}
			
			const products = useProductsStore().selected.filter(p => {
				if (p.selected_component_uid) {
					const associatedComponent = componentsStore.by_uid[p.selected_component_uid]
					
					if (associatedComponent.properties.declined || associatedComponent.properties.already_ordered) {
						return false
					}
				}
				
				return true
			}).map(p => ({
				uid: p.product_id,
				product_type: p.product_type || null,
				component_uid: p.selected_component_uid
			}))
			
			let exports: Record<string, any> = {}
			
			if (this.exports.length) {
				const context: MultiExportContext = {
					availableByUID: createRecord(this.exports, e => e.uid),
					personsByID,
					validatedOnly: genericStore.dev.submission_payload_validated_only
				}
				
				for (let page of this.pages) {
					const newExports = await exportFromComponents(page.components, context)
					exports = merge(exports, newExports)
				}
			}
			
			const payload: ProtocolSubmission = {
				_version: String(extractionVersion),
				_host: window.location.host,
				_build: import.meta.env.BUILD_NUMERIC,
				
				order: {
					type: this.order.type,
					external_id: this.order.external_id
				},
				
				products,
				images,
				items,
				components: values,
				exports,
				persons,
				ticket_id: this.ticket_id as string,
				started_at: this.start_date as number,
				payload_created_at: DateTimeExt.now().getTimestamp(),
				payload_submitted_at: 0,
				client_short: this.client.short as string,
				user_agent: navigator.userAgent ?? null
			}
			
			// Extract meter information using the semantics
			// -to be replaced by proper exports soon-
			if (extractionVersion == ExtractionVersion.V3_0) {
				payload.meters = extractMetersFromPages(this.pages)
			}
			
			return payload
		},
		
		setCreatePagesPDF(state: boolean) {
			this.create_pages_pdf = state
		},
		
		togglePageWithLinks(arg: Page|UID, enabled: boolean) {
			let page: Page
			
			if (!isPage(arg)) {
				// Find page
				let p = this.pages_by_uid[arg]
				
				if (p) {
					page = p
				} else {
					// We don't crash. This can happen if a page was conditionally disabled from the backend and the link
					// was not removed for convenience
					log.debug('Trying to toggle page which could not be found: ' + arg)
					return
				}
			} else {
				page = arg
			}
			
			if (!page) {
				log.always.warn('Page', arg, 'not found to toggle')
				return
			}
			
			const pageStore = usePageStore()
			
			// Toggle page itself
			pageStore.setEnabled(enabled, page!)
			
			// Then its links
			if (Array.isArray(page!.data.linked)) {
				let linked = this.pages.filter(p => (p.data.linked ?? []).indexOf(p.data.uid) > -1)
				
				for (let link of linked) {
					pageStore.setEnabled(enabled, link)
				}
			}
		},
		
		disablePageWithLinks(arg: Page|UID) {
			this.togglePageWithLinks(arg, false)
		},
		
		enablePageWithLinks(arg: Page|UID) {
			this.togglePageWithLinks(arg, true)
		},
		
		evaluatePageDisplayConditions(page: Page|UID|null) {
			const componentsStore = useComponentsStore(),
				productsStore = useProductsStore(),
				personsStore = usePersonsStore()
			
			const context: EvaluationContext = {
				componentsByUID: componentsStore.by_uid,
				pagesByUID: this.pages_by_uid,
				productsByUID: productsStore.by_uid,
				personsByID: personsStore.persons,
				scope: {}
			}
			
			const evaluatePage = (page: Page) => new Promise(resolve => {
				// Check office first
				if (page.display_offices && this.office?.id) {
					const officeMatches = page.display_offices.offices.includes(this.office.id)
					
					if (page.display_offices.mode == DisplayOfficesMode.INCLUDE) {
						if (!officeMatches) {
							resolve(false)
						}
						
						// continue with generic conditions
					} else if (page.display_offices.mode == DisplayOfficesMode.EXCLUDE) {
						if (officeMatches) {
							resolve(false)
						}
						
						// continue with generic conditions
					}
				}
				
				// Then generic display condition
				if (page.display_condition) {
					const evaluated = evaluateCondition(page.display_condition, context)
					
					this.togglePageWithLinks(page, evaluated)
					resolve(evaluated)
				} else {
					resolve(true)
				}
			})
			
			let promises: Promise<any>[] = []
			
			if (typeof(page) == 'string') {
				let p = this.pages_by_uid[page]
				
				if (p) {
					promises.push(evaluatePage(p))
				} else {
					log.warn('evaluatePageDisplayConditions', 'Could not find page', page)
				}
			} else if (isPage(page)) {
				// Evaluate only this one page
				promises.push(evaluatePage(page))
			} else {
				// Evaluate all pages
				for (let p of this.pages) {
					promises.push(evaluatePage(p))
				}
			}
			
			return Promise.all(promises)
		},
		
		getPageFromArg(arg: Page|UID|object, errorMessage?: string) {
			if (arg && (arg as any).id) {
				arg = (arg as any).id
			}
			
			if (!isPage(arg)) {
				// Find page
				let p = this.pages_by_uid[arg as string]
				
				if (p) {
					return p
				} else {
					throw new Error(errorMessage || 'Could not find page: ' + arg)
				}
			} else {
				return arg
			}
		},
		
		setLanguage(language: string) {
			this.language = language
		},
		
		validatePage(arg: Page|UID) {
			const page = this.getPageFromArg(arg, 'Trying to validate page which could not be found: ' + arg)
			const pageStore = usePageStore()
			
			return pageStore.validate(page)
		},
		
		invalidatePage(arg: Page|UID|object) {
			const page = this.getPageFromArg(arg, 'Trying to invalidate page which could not be found: ' + arg)
			const pageStore = usePageStore()
			
			pageStore.invalidate(page)
			
			if (!pageHasSemantic(page, 'signature')) {
				this.invalidateSignaturePage()
			}
		},
		
		invalidateSignaturePage() {
			for (let page of this.pages) {
				if (pageHasSemantic(page, 'signature')) {
					if (page.data.validated) {
						useLogsStore().info('[protocol]', 'Invalidating signatures')
					}
					
					this.invalidatePage(page)
				}
			}
		},
		
		async submit(token: string) {
			const backend = useBackend()
			let payload = await this.createSubmissionPayload()
			
			if (!payload) {
				throw new Error('Invalid submission payload')
			}
			
			payload = clone(payload)
			
			// Generate Screenshot PDF if enabled
			if (this.create_pages_pdf) {
				const screenshotsStore = useScreenshotsStore()
				const dataURI = await screenshotsStore.createPDF()
				
				payload.protocol_pdf = dataURI as string
			}
			
			payload.payload_submitted_at = DateTimeExt.now().getTimestamp()
			
			try {
				return await backend.submitProtocol(token, payload)
			} catch (e: any) {
				if (e?.response?.status == 409) {
					try {
						// This case shouldn't even happen, so log it to raygun
						throw new Error('Duplicate protocol submission resulting in 409')
					} catch (trace: any) {
						useRaygun().send(trace)
					}
				}
				
				throw e
			}
		},
	}
})

export const createAcquisitUIPagesStoreAdapter = (): PagesStore => {
	const protocolStore = useProtocolStore()
	
	return {
		pages: computed<Page[]>({
			get() {
				return protocolStore.pages
			},
			
			set(value) {
				protocolStore.pages = value
			}
		}),
		
		byUID: toRef(() => protocolStore.pages_by_uid),
		invalidatePage: protocolStore.invalidatePage,
		invalidateSignaturePage: protocolStore.invalidateSignaturePage,
		pagesWithComponentOfTag: protocolStore.pagesWithComponentOfTag,
		pagesWithSemantic: protocolStore.pagesWithSemantic
	}
}
