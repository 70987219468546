import type { Component, ComponentTagDictionary, Page } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import type { ValidationError } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { useLogger, validateComponents } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { defineStore } from 'pinia'
import { cast, clone, pageHasSemantic } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import { useProductsStore } from '@/stores/products'
import { setProperties, useComponentsStore } from '@/stores/components'
import { usePersonsStore } from '@/stores/persons'
import { useProtocolStore } from '@/stores/protocol'

const log = useLogger('stores/page', useLogger.COLOR_STORE)

export interface CurrentPageStoreState {
	current_page: Page|null
}

export const usePageStore = defineStore({
	id: 'page',
	
	state: () => cast<CurrentPageStoreState>({
		current_page: null
	}),
	
	getters: {
		current: state => state.current_page,
		current_uid: state => state.current_page?.data.uid,
		
		components_by_tag: state => {
			let byTag: ComponentTagDictionary = {}
			
			if (!state.current_page) {
				return byTag
			}
			
			let getComponents = children => {
				let ret: Component[] = []
				
				for (let child of children || []) {
					ret.push(child)
					ret = ret.concat(getComponents(child.children || []))
				}
				
				return ret
			}
			
			let allComponents = getComponents(state.current_page.components)
			
			for (let component of allComponents) {
				if (!(component.tag_normalized in byTag)) {
					byTag[component.tag_normalized] = []
				}
				
				byTag[component.tag_normalized].push(component)
			}
			
			return byTag
		},
		
		backup: state => {
			let backup = clone((state as CurrentPageStoreState & { $state: CurrentPageStoreState }).$state) as any
			
			for (let component of backup.current_page.components) {
				component.properties.errors = null
			}
			
			backup.current_page.data.errors = null
			
			return backup
		}
	},
	
	actions: {
		setCurrentPage(page: Page) {
			this.current_page = page
		},
		
		setErrors(errors: ValidationError[], page?: Page) {
			if (!page) {
				if (!this.current_page) {
					log.always.warnFrom('setErrors', 'No current page is set')
					return
				}
				
				page = this.current_page
			}
			
			page.data.errors = errors
		},
		
		async validate(page?: Page): Promise<true> {
			if (!page) {
				if (!this.current_page) {
					log.always.warnFrom('validate', 'No current page is set')
					throw new Error('No current page is set')
				}
				
				page = this.current_page
			}
			
			try {
				const componentsStore = useComponentsStore(),
					productsStore = useProductsStore(),
					personsStore = usePersonsStore(),
					protocolStore = useProtocolStore()
				
				await validateComponents({
					components: page.components,
					componentsByUID: componentsStore.by_uid,
					componentsByTag: componentsStore.by_tag,
					productsByUID: productsStore.by_uid,
					personsByID: personsStore.by_id,
					pagesByUID: protocolStore.pages_by_uid,
					owner: page,
					setProperties
				})
				
				page.data.validated = true
				
				return true
			} catch (e) {
				this.invalidate(page)
				throw e
			}
		},
		
		invalidate(page?: Page) {
			if (!page) {
				if (!this.current_page) {
					log.always.warnFrom('invalidate', 'No current page is set')
					return
				}
				
				page = this.current_page
			}
			
			page.data.validated = false
		},
		
		setEnabled(enabled: boolean, page?: Page) {
			if (!page) {
				if (!this.current_page) {
					log.always.warnFrom('setEnabled', 'No current page is set')
					return
				}
				
				page = this.current_page
			}
			
			if (!page) {
				log.always.warnFrom('setEnabled', 'No page found')
				return
			}
			
			const isProductPage = pageHasSemantic(page, 'product')
			
			if (isProductPage && !enabled) {
				const productsStore = useProductsStore()
				
				let products = productsStore.forPage(page),
					chooser: Component|null = null
				
				// Find the first component that has products - there can only be one chooser per page
				for (let component of page.components) {
					if (Array.isArray(component.properties.products)) {
						chooser = component
						break
					}
				}
				
				for (let product of products) {
					// FIXME Remove soon
					if (!product) {
						continue
					}
					
					if (!product.selected_component_uid || (chooser && product.selected_component_uid == chooser.properties.uid)) {
						productsStore.unselect(product)
					}
				}
			}
			
			page.enabled = enabled
			
			if (!isProductPage && !enabled) {
				page.data.validated = false
			}
		},
		
		restoreBackup(_: Record<any, any>) {
			// Ignore any backups
		}
	}
})