<template>
	<div id="acquisit-bankid" :tabindex="0" :aria-label="language.parse(ariaLabel)">
		<div :class="['auth-wrapper', { ['status-' + status]: status }]" tabindex="-1" aria-hidden="true">
			<div class="auth" :style="{ color: authColor?.cssHSLA }">
				<v-svg v-if="status == BANKID_STATUS_SUCCESS" class="icon" file="acq/authorized" multi-color />
				<v-svg v-if="status == BANKID_STATUS_ERROR" class="icon" file="acq/unauthorized" multi-color />

				<template v-if="status == BANKID_STATUS_ERROR">
					<div class="message error">
						<acquisit-string class="text" :source="authMessage" />
					</div>
				</template>

				<template v-else-if="status == BANKID_STATUS_SUCCESS">
					<div class="message success">
						<acquisit-string class="text" :source="authMessage" />
						<span class="meta">
							<span class="item" v-if="authName">
								<v-svg class="icon" file="acq/person" />
								{{ authName }}
							</span>

							<span class="item" v-if="authDateFormatted">
								<v-svg class="icon" file="acq/clock-check-outline" />
								{{ authDateFormatted }}
							</span>
						</span>
					</div>
				</template>

				<template v-else>
					<div class="message waiting">
						<span class="text">Venter…</span>
					</div>
				</template>
				
				<div class="focus-ring"></div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { type PropType } from 'vue'
	import { useBackend } from '@/lib/backend'
	import { useAPIStore } from '@/stores/api'
	import { type Person, type UILabelOptional, type UILabel, useRaygun } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { useComponentLogger } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { useBaseComponentProps, useBaseComponentEmits, useBaseComponent } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import { timestampToHumanString } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	import { computed, onMounted, ref, watch } from 'vue'
	import { parseSignature, usePersonsStore } from '@/stores/persons'
	import type { ParsedBankIDSignature } from '@/lib/types'
	import { encryptAES_GCM, isCryptoAvailable } from '@/lib/functions/crypto'
	import { errorMessage } from '@/lib/ui'
	import { useUIStore } from '@/stores/ui'
	import { useRoute, useRouter } from 'vue-router'
	import { useProtocolStore } from '@/stores/protocol'
	import { useGenericStore } from '@/stores/generic'
	
	const BANKID_STATUS_SUCCESS = 'success'
	const BANKID_STATUS_ERROR = 'error'
	
	const props = defineProps({
		...useBaseComponentProps(),
		
		person: {
			type: Object as PropType<Person>,
			required: true
		},
		
		mode: {
			type: String,
			required: false,
			default: 'init',
			validator: (val: any) => ['init', 'verify'].includes(val)
		},
		
		refreshLabel: {
			type: [String, Object] as PropType<UILabel>,
			default: null
		},
		
		successText: {
			type: [String, Object] as PropType<UILabel>,
			default: null
		},
		
		errorText: {
			type: [String, Object] as PropType<UILabel>,
			default: null
		},
		
		modelValue: {
			type: String,
			required: false,
			default: undefined
		}
	})
	
	const emit = defineEmits([
		...useBaseComponentEmits(),
		'update:modelValue'
	])
	
	const base = useBaseComponent(props, emit)
	const { color, language } = base
	
	const log = useComponentLogger('components/ui/extended/BankId', props)
	
	const apiStore = useAPIStore()
	const protocolStore = useProtocolStore()
	
	const $router = useRouter(),
		$route = useRoute()
	
	const authMessage = ref<UILabelOptional>()
	const authName = ref<string|null>()
	const authDate = ref<Date|null>()
	const status = ref<string|null>()
	
	const method = computed(() => {
		if (props.person.signature_type?.type == 'bankid_mobile') {
			return 'no-bid-m'
		}
		
		return 'no-bid'
	})
	
	const authDateFormatted = computed(() => {
		if (!(authDate.value instanceof Date)) {
			return null
		}
		
		return timestampToHumanString(authDate.value)
	})
	
	const authColor = computed(() => {
		if (authMessage.value == props.successText) {
			return color.parse('#222222')
		} else if (authMessage.value == props.errors) {
			return color.parse('#d32e2e')
		}
		
		return undefined
	})
	
	const ariaLabel = computed(() => {
		switch (status.value) {
			case BANKID_STATUS_SUCCESS:
				return authName.value ?
				       authMessage.value + ' som ' + authName.value :
				       authMessage.value
			
			case BANKID_STATUS_ERROR:
				return authMessage.value
			
			default:
				return 'Vennligst venter…'
		}
	})
	
	const updateAuthData = () => {
		if (props.person.signature) {
			const attributes = parseSignature(props.person.signature) as ParsedBankIDSignature|null
			
			if (attributes) {
				authName.value = attributes.name || null
				authDate.value = attributes.timestamp ? new Date(attributes.timestamp * 1000) : null
			}
		}
	}
	
	const createReturnURL = async () => {
		// Get URL to redirect to, append information to restore when the user comes back
		const currentURL = new URL(window.location.href)
		currentURL.searchParams.delete('bid')
		currentURL.searchParams.delete('sessionId')
		currentURL.searchParams.delete('status')
		
		// Gather selected persons and their info
		const queryInfo: any[] = []
		let selected = usePersonsStore().selected
		
		for (let person of selected) {
			queryInfo.push({
				id: person.id,
				on_behalf: person.on_behalf
			})
		}
		
		const queryJSON = JSON.stringify(queryInfo)
		
		if (isCryptoAvailable()) {
			const password = (useAPIStore().token ?? 'default').substring(0, 7)
			const queryCrypted = await encryptAES_GCM(queryJSON, password)
			currentURL.searchParams.set('bid', encodeURIComponent(queryCrypted))
		} else {
			// No crypto available, probably because of local. Warn about that.
			console.warn('crypto is not available')
			
			const queryEncoded = window.btoa(JSON.stringify(queryInfo))
			currentURL.searchParams.set('bid', encodeURIComponent(queryEncoded))
		}
		
		return currentURL
	}
	
	const openBankID = async () => {
		const currentURL = new URL(self.location.href)
		
		if (currentURL.protocol === 'http:' && import.meta.env.DEV) {
			currentURL.protocol = 'https:'
			await errorMessage('BankID requires HTTPS. <a href="' + currentURL.href + '">Switch to HTTPS</a>')
			return
		}
		
		const returnURL = await createReturnURL()
		const bankIDURL = useBackend().getBankIDURL(apiStore.token!, props.person.id, returnURL.href)
		
		log.debugFrom('openBankID', bankIDURL)
		log.debugFrom('openBankID', 'Return to', returnURL.href)
		
		window.location.href = bankIDURL
	}
	
	/**
	 * Verify the current session if there is one and consume it, replacing with a cleaner URL after
	 * (determined by query params "status" and "sessionId")
	 */
	const verifyCurrentSession = async () => {
		log.debugFrom('verifyCurrentSession', 'Fetching data')
		
		const currentURL = new URL(window.location.href)
		const returnStatus = currentURL.searchParams.has('status') ? currentURL.searchParams.get('status') : 'abort'
		const sessionID = currentURL.searchParams.has('sessionId') ? currentURL.searchParams.get('sessionId') : null
		
		if (returnStatus === 'success' && sessionID) {
			useUIStore().setLoader(true)
			
			try {
				const data = await useBackend().getBankIDData(apiStore.token!, props.person.id, sessionID)
				
				const commonName = data.common_name,
					personNumber = data.person_number,
					timestamp = data.timestamp
				
				const signature = `bankid:${commonName};${personNumber};${timestamp}`
				
				status.value = BANKID_STATUS_SUCCESS
				authMessage.value = props.successText
				authName.value = commonName
				authDate.value = new Date(timestamp * 1000)
				
				emit('update:modelValue', signature)
				log.infoFrom('verifyCurrentSession', 'Verified')
				
				// Remove consumed query params
				await removeSessionQueryParams()
			} catch (e: any) {
				log.always.errorFrom('verifyCurrentSession', 'Could not verify session', e)
				setError()
				
				emit('update:modelValue', null)
				useRaygun().send(e, ['bankID'])
			} finally {
				useUIStore().setLoader(false)
			}
		} else {
			// error or abort
			setError()
			emit('update:modelValue', null)
		}
	}
	
	const createDummySignature = () => {
		return `bankid:${props.person.firstname} ${props.person.name};010101-12345;${Math.round(+new Date() / 1000)}`
	}
	
	const removeSessionQueryParams = async () => {
		await $router.replace({
			...$route,
			query: {
				...$route.query,
				bid: undefined,
				sessionId: undefined,
				status: undefined
			}
		})
	}
	
	const retryAuth = () => {
		reset()
	}
	
	const setError = () => {
		authMessage.value = props.errorText
		status.value = BANKID_STATUS_ERROR
		authDate.value = null
		authName.value = null
	}
	
	const reset = () => {
		authMessage.value = undefined
		status.value = null
		authDate.value = null
	}
	
	onMounted(() => {
		if (props.mode == 'verify') {
			verifyCurrentSession()
		} else if (props.person.signature) {
			authMessage.value = props.successText
			status.value = BANKID_STATUS_SUCCESS
		} else if (useAPIStore().isPreview) {
			alert('BankID signature is unavailable in previews. Simulation will assume a successful signature.')
			emit('update:modelValue', createDummySignature())
			
			authMessage.value = props.successText
			status.value = BANKID_STATUS_SUCCESS
			
			updateAuthData()
		} else {
			setTimeout(() => {
				if ((import.meta.env.DEV || import.meta.env.ACQUISIT_MODE == 'staging') && !protocolStore.backed_up) {
					alert('BankID signature requires that the protocol has been backed up at least once.\n\nPlease save a page before continuing.')
					setError()
					
					useGenericStore().backup_enabled = true
					return
				}
				
				openBankID()
			}, 300)
		}
	})
	
	watch(() => props.person, () => {
		updateAuthData()
	}, {
		immediate: true
	})
	
	defineExpose({
		...base.expose,
		retryAuth,
		openBankID,
	})
</script>

<style lang="scss" scoped>
	@use '@/assets/mixins.scss' as m;

	#acquisit-bankid {
		outline: 0;
		
		iframe {
			position: fixed;
			top: 0;
			left: 0;
			background: white;
			width: 100%;
			height: 100%;
			z-index: 9999;
		}

		.auth-wrapper {
			height: 150px;
			position: relative;
			background: m.color("light-background");
			transition: background 0.2s;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;

			@include m.media(mobile) {
				margin: {
					left: -24px;
					right: -24px;
				}
			}

			.auth {
				display: flex;
				align-items: center;
				margin-left: -20px;

				> .icon {
					margin: 10px;
					width: 42px;
					height: 42px;

					:deep(svg) {
						width: inherit;
						height: inherit;
					}
				}

				.message {
					display: block;
					font-size: 18px;
					font-weight: 400;

					.text,
					.meta {
						display: block;
					}

					.text + .meta {
						margin-top: 12px;
					}

					.meta {
						font-size: 0.9rem;
						color: m.color("light-grey-blue");

						.icon {
							width: 16px;
							height: 16px;
							display: inline-flex;
							vertical-align: middle;
							margin-top: -3px;
						}

						.item {
							display: block;

							.icon {
								margin-right: 4px;
							}

							& + .item {
								margin-top: 8px;
							}
						}
					}
				}
			}
			
			&.status-error {
				background: rgba(m.color("red"), 0.067);
			}

			&.status-success {
				background: m.color("light-background");
				
				.auth {
					align-items: flex-start;
					padding: {
						top: 4px;
					}
					
					.icon {
						margin-top: -21px + 6px;
					}
				}
			}
		}

		.button-wrapper {
			text-align: right;

			.refresh-button {
				display: inline-block;
			}
		}
		
		.focus-ring {
			@include m.focus-ring;
			
			top: 8px;
			bottom: 8px;
			left: 8px;
			right: 8px;
		}
		
		&:focus .focus-ring {
			@include m.focus-ring-visible;
		}
	}

	@keyframes error {
		10%, 90% {
			transform: translate3d(-1px, 0, 0);
		}

		20%, 80% {
			transform: translate3d(2px, 0, 0);
		}

		30%, 50%, 70% {
			transform: translate3d(-4px, 0, 0);
		}

		40%, 60% {
			transform: translate3d(4px, 0, 0);
		}
	}
</style>
