<template>
	<div class="acquisit-state-inspector-imports-exports default-wrapper">
		<StateInspectorHeader class="header">
			<template #default>
				<h2>Imports & Exports</h2>
			</template>
			
			<template #actions>
			
			</template>
		</StateInspectorHeader>
		
		<acquisit-tabs theme="integrated-dark">
			<acquisit-tab title="Imports">
				<StateInspectorAvailableImportExport v-for="entry in protocolStore.imports"
				                                     :entry="entry" />
				
				<div class="empty" v-if="!protocolStore.imports.length">
					No imports available.
				</div>
			</acquisit-tab>
			
			<acquisit-tab title="Exports">
				<StateInspectorAvailableImportExport v-for="entry in protocolStore.exports"
				                                     :entry="entry" />
				
				<div class="empty" v-if="!protocolStore.exports.length">
					No exports available.
				</div>
			</acquisit-tab>
		</acquisit-tabs>
	</div>
</template>

<script setup lang="ts">
	import StateInspectorHeader from '@ui/dev/StateInspectorHeader.vue'
	import { useProtocolStore } from '@/stores/protocol'
	import StateInspectorAvailableImportExport from '@ui/dev/StateInspectorAvailableImportExport.vue'
	
	const protocolStore = useProtocolStore()
</script>

<style lang="scss">
	@use '@/assets/mixins.scss' as m;
	
	.acquisit-state-inspector-imports-exports {
	
		.empty {
			text-align: center;
			padding: 24px 16px;
		}
	}
</style>