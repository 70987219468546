<template>
	<div class="maintenance" v-if="maintenance.isEnabled.value">
		<div class="background-icon">
			<v-svg file="acq/tools" />
		</div>
		
		<div class="box">
			<img src="/images/branding/meglerfront_for_dark.svg"
			     class="logo"
			     alt="Meglerfront Logo" />
			
			<h1 v-html="maintenance.message" />
		</div>
		
		<span class="build">
			Build: {{ build }}
		</span>
	</div>
	
	<template v-else>
		<RouterView />
	</template>
</template>

<script setup lang="ts">
	import { useProtocolStore } from '@/stores/protocol'
	import { storeToRefs } from 'pinia'
	import { computed, watch } from 'vue'
	import { useMaintenance } from '@/helpers/maintenance'
	
	const { language } = storeToRefs(useProtocolStore())
	
	const maintenance = useMaintenance()
	const build = computed(() => import.meta.env.BUILD)
	
	watch(language, (l) => {
		if (l) {
			document.documentElement.setAttribute('lang', l)
		} else {
			document.documentElement.removeAttribute('lang')
		}
	})
</script>

<style lang="scss">
	@use 'sass:color';
	@use '@/assets/mixins.scss' as m;
	
	:root {
		--text-secondary: #{m.color("light-grey-blue")};
		--red: #{m.color("red")};
		--red-2: #{m.color("red-2")};
		--red-3: #{m.color("red-3")};
		--dark-red: #{m.color("dark-red")};
		--pink: #{m.color("pink")};
		--indigo: #{m.color("indigo")};
		--purple: #{m.color("purple")};
		--purple-2: #{m.color("purple-2")};
		--purple-3: #{m.color("purple-3")};
		--deep-purple: #{m.color("deep-purple")};
		--deep-purple-2: #{m.color("deep-purple-2")};
		--deep-purple-3: #{m.color("deep-purple-3")};
		--cyan: #{m.color("cyan")};
		--teal: #{m.color("teal")};
		--green: #{m.color("green")};
		--green-2: #{m.color("green-2")};
		--green-3: #{m.color("green-3")};
		--light-green: #{m.color("light-green")};
		--light-green-2: #{m.color("light-green-2")};
		--light-green-3: #{m.color("light-green-3")};
		--lime: #{m.color("lime")};
		--yellow: #{m.color("yellow")};
		--amber: #{m.color("amber")};
		--orange: #{m.color("orange")};
		--deep-orange: #{m.color("deep-orange")};
		--brown: #{m.color("brown")};
		--grey: #{m.color("grey")};
		--dark-grey: #{m.color("dark-grey")};
		--light-grey: #{m.color("light-grey")};
		--light-grey-blue: #{m.color("light-grey-blue")};
		--blue-grey: #{m.color("blue-grey")};
	}
	
	html, body {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		height: 100%;
		width: 100%;
		position: fixed;
		overflow: hidden;
		background: #fff;
		
		@include m.main-font(15px);
	}
	
	sup {
		vertical-align: super;
		font-size: smaller;
	}
	
	sub {
		vertical-align: sub;
		font-size: smaller;
	}
	
	#app {
		height: 100%;
		width: 100%;
		position: fixed;
		overflow: hidden;
	}
	
	.maintenance {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		height: 100%;
		
		background: #091521;
		color: #fff;
		
		.background-icon {
			position: absolute;
			/*bottom: -8%;
			left: -12%;*/
			
			top: 50%;
			left: 50%;
			
			transform: translate(-50%, -50%);
			
			width: 100%;
			height: 100%;
			max-width: 820px;
			
			z-index: 2;
			
			color: color.adjust(#091521, $lightness: 4%, $saturation: -16%, $space: hsl);
			
			transition: transform 0.4s, width 0.4s;
			
			.svg {
				width: 100%;
				height: 100%;
			}
			
			@media screen and (max-width: 620px) {
				transform: translate(-50%, -70%);
			}
		}
		
		.box {
			display: flex;
			flex-direction: inherit;
			justify-content: inherit;
			align-items: inherit;
			gap: 20px;
			
			background: rgba(#000, 0.67);
			padding: 32px 36px;
			border-radius: 10px;
			backdrop-filter: blur(8px);
			
			width: 100%;
			
			position: relative;
			z-index: 9002;
		}
		
		h1 {
			font-size: 1.67rem;
			font-weight: 400;
			line-height: 1.3;
			color: var(--text-secondary);
			
			strong {
				display: inline-block;
				font-weight: 900;
				font-size: 2.0rem;
				color: rgba(#fff, 1.0);
			}
		}
		
		.build {
			color: var(--text-secondary);
			font-size: 0.9rem;
			letter-spacing: 1px;
			text-transform: uppercase;
			opacity: 0.67;
			margin-top: 24px;
			font-weight: 600;
			position: relative;
			z-index: 5002;
			text-shadow: 0px 2px 1px #000;
		}
		
		.logo {
			height: 48px;
		}
	}
</style>
