<!--
    @author bluefirex
    @date 2020-05-06
-->
<template>
	<div :class="['acquisit-legacy-product-person-chooser-person', { error: erroring && errorsMapped.generic.length, 'no-interact': noInteract }]">
		<div class="flex" @click="$emit('click', $event)">
			<div class="checkbox">
				<acquisit-checkbox :uid="'person-' + person.id + '-selected'"
				                   :radio="radio"
				                   :disabled="disabled"
				                   :errors="Boolean(erroring && errorsMapped.generic.length)"
				                   no-interact
				                   :display-only="displayOnly"
				                   :aria-label="personName"
				                   v-model="person.marked" />
			</div>
			
			<div class="meta" :id="uid + '-label'">
				{{ personName }}
			</div>
		</div>
		
		<div class="billing-address" v-if="billing_address_label && person.marked && addressComponents">
			<acquisit-components-container :components="addressComponents"
			                               ref="$addressComponents"
			                               @update:modelValue="onAddressComponentsInput"
			                               :no-interact="noInteract"
			                               :display-only="displayOnly"
			                               :errors="errorsMapped.billing_address.concat(errorsMapped.generic)"
			                               :has-current-errors="erroring" />
		</div>
	</div>
</template>

<script setup lang="ts">
	import type { Component, Person, Address, BillingAddressType } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { ComponentsContainer, ValidationError, useComponentLogger, extractValuesFromComponents } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { useBaseComponentProps, useBaseComponentEmits, useBaseComponent } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import { collectByUID, createComponent } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	import { useProtocolStore } from '@/stores/protocol'
	import type { PropType } from 'vue'
	import { computed, onMounted, ref, watch } from 'vue'
	
	const props = defineProps({
		...useBaseComponentProps(),
		
		person: {
			type: Object as PropType<Person>,
			required: true
		},
		
		radio: {
			type: Boolean,
			required: false,
			default: false
		},
		
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		
		billing_address_label: {
			type: String,
			required: false,
			default: null
		},
		
		billing_postcode_label: {
			type: String,
			required: false,
			default: null
		},
		
		billing_city_label: {
			type: String,
			required: false,
			default: null
		},
		
		/*
		 Choices of addresses
		 */
		
		use_person_address_label: {
			type: String,
			required: false,
			default: null
		},
		
		use_person_address_checked: {
			type: Boolean,
			required: false,
			default: false
		},
		
		use_estate_address_label: {
			type: String,
			required: false,
			default: null
		},
		
		use_estate_address_checked: {
			type: Boolean,
			required: false,
			default: false
		},
		
		use_custom_address_label: {
			type: String,
			required: false,
			default: null
		},
		
		use_custom_address_checked: {
			type: Boolean,
			required: false,
			default: false
		},
	})
	
	const emit = defineEmits([
		...useBaseComponentEmits(),
		'click',
		'update:modelValue'
	])
	
	const base = useBaseComponent(props, emit)
	const { erroring } = base
	
	const log = useComponentLogger('components/ui/ProductPersonChooserLegacy', props)
	
	const protocolStore = useProtocolStore()
	
	const billingAddress = ref<BillingAddressType>(props.person.billing_address || {
		address: null,
		postcode: null,
		city: null,
		country: null
	})
	
	const addressComponentOptions = ref<any>(null)
	const addressComponents = ref<any>(null)
	const $addressComponents = ref<typeof ComponentsContainer|undefined>()
	
	const errorsMapped = computed(() => {
		let errors = {
			generic: [] as ValidationError[],
			billing_address: [] as ValidationError[]
		}
		
		if (!props.errors || !props.errors.length) {
			return errors
		}
		
		for (let error of props.errors) {
			if (error.owner?.person?.id) {
				switch (error.key) {
					case 'billing_address.address':
					case 'billing_address.postcode':
					case 'billing_address.city':
						error = error.clone()
						error.reference = addressComponentOptions.value.custom_address
						errors.billing_address.push(error)
						
						break
					
					case 'billing_address':
						for (let key in addressComponentOptions.value) {
							if (addressComponentOptions.value.hasOwnProperty(key)) {
								let e = error.clone()
								e.reference = addressComponentOptions.value[key]
								e.key = 'modelValue'
								errors.generic.push(e)
							}
						}
						
						break
					
					default:
						log.warnFrom('errorsMapped', 'Unknown error reference:', error.key)
				}
			} else {
				errors.generic.push(error)
			}
		}
		
		return errors
	})
	
	const personName = computed(() => {
		return [
			props.person.firstname,
			props.person.name
		].filter(x => !!x).join(' ')
	})
	
	const hasMultipleOptions = computed(() => {
		return [!!props.use_person_address_label, !!props.use_estate_address_label]
			       .filter(x => x)
			       .length > 0
	})
	
	const estateAddress = computed(() => {
		return protocolStore.order?.address
	})
	
	const personUsePersonAddress = computed({
		get() {
			return props.person.billing_address.use_person_address
		},
		
		set(value) {
			props.person.billing_address.use_person_address = value
		}
	})
	
	const personUseEstateAddress = computed({
		get() {
			return props.person.billing_address.use_estate_address
		},
		
		set(value) {
			props.person.billing_address.use_estate_address = value
		}
	})
	
	const personUseCustomAddress = computed({
		get() {
			return props.person.billing_address.use_custom_address
		},
		
		set(value) {
			props.person.billing_address.use_custom_address = value
		}
	})
	
	const generateAddressComponents = () => {
		let components = createAddressComponentOptions()
		addressComponentOptions.value = components
		
		if (hasMultipleOptions.value) {
			let filtered = [
				components.use_person_address,
				components.use_estate_address,
				components.use_custom_address
			].filter(c => !!c)
			
			if (filtered.length > 1) {
				addressComponents.value = filtered
			} else {
				personUseCustomAddress.value = true
				
				addressComponents.value = [
					components.custom_address
				]
			}
		} else {
			addressComponents.value = [
				components.custom_address
			]
		}
	}
	
	const createAddressComponentOptions = () => {
		let usePersonAddressCheckbox: Component|null = null,
			useEstateAddressCheckbox: Component|null = null,
			useCustomAddressCheckbox: Component|null = null
		
		let customAddress = createComponent('acquisit-product-person-chooser-address', {
			uid: 'custom-address',
			modelValue: billingAddress.value,
			address_label: props.billing_address_label,
			postcode_label: props.billing_postcode_label,
			city_label: props.billing_city_label
		}, true)
		
		if (props.use_person_address_label && props.person.address.address) {
			usePersonAddressCheckbox = createComponent('acquisit-checkbox', {
				uid: 'use-person-address',
				label: formatAddressLabel(props.use_person_address_label, props.person.address),
				radio: true,
				modelValue: personUsePersonAddress.value,
				disabled: Boolean(!personUsePersonAddress.value && (personUseEstateAddress.value || personUseCustomAddress.value))
			}, true)
		}
		
		if (props.use_estate_address_label) {
			useEstateAddressCheckbox = createComponent('acquisit-checkbox', {
				uid: 'use-estate-address',
				label: formatAddressLabel(props.use_estate_address_label, estateAddress.value),
				radio: true,
				modelValue: personUseEstateAddress.value,
				disabled: Boolean(!personUseEstateAddress.value && (personUsePersonAddress.value || personUseCustomAddress.value))
			}, true)
		}
		
		if (props.use_custom_address_label) {
			useCustomAddressCheckbox = createComponent('acquisit-checkbox', {
				uid: 'use-custom-address',
				label: props.use_custom_address_label,
				radio: true,
				modelValue: personUseCustomAddress.value,
				disabled: Boolean(!personUseCustomAddress.value && (personUseEstateAddress.value || personUsePersonAddress.value))
			}, true, [
				customAddress
			])
		}
		
		let renderedComponents = [usePersonAddressCheckbox, useEstateAddressCheckbox, useCustomAddressCheckbox].filter(x => x !== null) as Component[]
		
		for (let a of renderedComponents) {
			for (let b of renderedComponents) {
				if (a.properties.uid != b.properties.uid) {
					if (!a.linked) {
						a.linked = []
					}
					
					a.linked.push(b.properties.uid)
				}
			}
		}
		
		if (useEstateAddressCheckbox && props.use_estate_address_checked) {
			if (!(usePersonAddressCheckbox?.properties.modelValue ?? false) && !(useCustomAddressCheckbox?.properties.modelValue ?? false)) {
				useEstateAddressCheckbox.properties.modelValue = true
				useEstateAddressCheckbox.properties.disabled = false
				
				personUseEstateAddress.value = true
				if (usePersonAddressCheckbox) personUsePersonAddress.value = false
				if (useCustomAddressCheckbox) personUseCustomAddress.value = false
			}
		}
		
		if (usePersonAddressCheckbox && props.use_person_address_checked) {
			if (!(useEstateAddressCheckbox?.properties.modelValue ?? false) && !(useCustomAddressCheckbox?.properties.modelValue ?? false)) {
				usePersonAddressCheckbox.properties.modelValue = true
				usePersonAddressCheckbox.properties.disabled = false
				
				personUsePersonAddress.value = true
				if (useEstateAddressCheckbox) personUseEstateAddress.value = false
				if (useCustomAddressCheckbox) personUseCustomAddress.value = false
			}
		}
		
		if (useCustomAddressCheckbox && props.use_custom_address_checked) {
			if (!(usePersonAddressCheckbox?.properties.modelValue ?? false) && !(useEstateAddressCheckbox?.properties.modelValue ?? false)) {
				useCustomAddressCheckbox.properties.modelValue = true
				useCustomAddressCheckbox.properties.disabled = false
				
				personUseCustomAddress.value = true
				if (usePersonAddressCheckbox) personUsePersonAddress.value = false
				if (useEstateAddressCheckbox) personUseEstateAddress.value = false
			}
		}
		
		return {
			use_person_address: usePersonAddressCheckbox,
			use_estate_address: useEstateAddressCheckbox,
			use_custom_address: useCustomAddressCheckbox,
			custom_address: customAddress
		}
	}
	
	const onAddressComponentsInput = (components: Component[]) => {
		addressComponents.value = components
		
		let values = extractValuesFromComponents({
			componentsByUID: collectByUID(components),
			personsByID: {},
			productsByUID: {}
		}, false)
		
		personUsePersonAddress.value = values.values['use-person-address'] ?? null
		personUseEstateAddress.value = values.values['use-estate-address'] ?? null
		personUseCustomAddress.value = values.values['use-custom-address'] ?? null
		
		if (!hasMultipleOptions.value || billingAddress.value.use_custom_address) {
			let customAddress = values.values['custom-address']
			
			for (let key in customAddress) {
				if (customAddress.hasOwnProperty(key)) {
					billingAddress.value[key] = customAddress[key]
				}
			}
		}
	}
	
	const formatAddressLabel = (label: string, address: Address|null|undefined) => {
		if (!address) {
			return label
		}
		
		let fullAddress = address.address
		
		if (address.postcode || address.city) {
			fullAddress += '<br /><span class="city">'
		}
		
		if (address.postcode) {
			fullAddress += address.postcode
		}
		
		if (address.city) {
			fullAddress += ' ' + address.city
		}
		
		if (address.postcode || address.city) {
			fullAddress += '</span>'
		}
		
		return label.replace(/{address}/g, fullAddress ?? '')
		            .replace(/{address.address}/g, address.address ?? '')
		            .replace(/{address.city}/g, address.city ?? '')
		            .replace(/{address.postcode}/g, address.postcode ?? '')
	}
	
	watch(billingAddress, (val) => {
		emit('update:modelValue', val)
	}, {
		deep: true
	})
	
	watch(erroring, (isErroring) => {
		if (isErroring) {
			for (let type in errorsMapped) {
				if (errorsMapped.hasOwnProperty(type)) {
					for (let error of errorsMapped[type]) {
						$addressComponents.value?.triggerErrorAnimation(error)
					}
				}
			}
		}
	})
	
	onMounted(() => {
		generateAddressComponents()
	})
	
	defineExpose({
		...base.expose
	})
</script>

<style lang="scss">
	@use '@/assets/mixins.scss' as m;
	
	.acquisit-legacy-product-person-chooser-person {
		border-top: 1px solid m.color("border");
		
		padding: {
			left: 20px;
			right: 20px;
			top: 8px;
			bottom: 8px;
		}
		
		.flex {
			display: flex;
			align-items: center;
		}
		
		.checkbox + .meta {
			padding-left: 8px;
		}
		
		.checkbox {
			width: 40px;
		}
		
		.meta {
			width: calc(100% - 40px);
		}
		
		.checkbox, .meta {
			cursor: pointer;
		}
		
		&.no-interact {
			
			.checkbox, .meta {
				cursor: default;
			}
		}
		
		.billing-address {
			margin-top: 8px;
			padding-left: 36px + 8px + 2px;
			padding-bottom: 12px;
		}
		
		&:last-child {
			border-bottom: 1px solid m.color("border");
		}
		
		&.error {
			color: red;
			animation: none;
			
			.meta {
				animation: error-shake 1s ease;
			}
		}
		
		@media screen and (max-width: 340px) {
			.billing-address {
				padding-left: 2px;
			}
		}
		
		@media screen and (max-width: 360px) {
			padding: {
				left: 0px;
				right: 0px;
			}
		}
		
		@media screen and (min-width: 361px) and (max-width: 480px) {
			padding: {
				left: 12px;
				right: 12px;
			}
		}
	}
</style>
