<!--
	@author bluefirex
-->
<template>
	<div class="acquisit-content-summary">
		<acquisit-span v-if="header" class="header" :label="header" />
		
		<div class="content-summary-pages">
			<div class="content-summary-page" v-for="page in pagesNormalized">
				<div class="page-title" :style="pageTitleStyle">
					{{ language.parse(page.data.name) }}
				</div>
				
				<div :class="['page-content', ...getSemanticClassesForPageContent(page)]">
					<template v-if="pageHasSemantic(page, 'persons') && page.person_collection">
						<div class="persons-type" v-for="(typeLabel, type) in page.person_collection.properties.types">
							<label class="type">{{ language.parse(typeLabel) }}:</label>
							
							<div class="persons">
								{{ personsStore.forType(type as any).map(p => [p.firstname, p.name].filter(x => !!x).join(' ')).join(', ') }}
							</div>
						</div>
						
						<acquisit-components-container display-only
						                               no-interact
						                               :components="page.components" />
					</template>
					
					<template v-else-if="pageHasSemantic(page, 'product')">
						<template v-if="page.product">
							<acquisit-single-product-card :product="page.product" :color="page.product.color || null" no-interact />
							<div style="clear: both;"></div>
							
							<div v-if="Array.isArray(page.product.checkboxes)"
							     class="product-checkboxes">
								<div v-for="checkbox in page.product.checkboxes"
								     class="product-checkbox">
									<acquisit-checkbox :modelValue="page.product.checkboxes_values?.[checkbox.uid] ?? false"
									                   no-interact display-only
									                   :color="page.product.color || null"
									                   :label="checkbox.label" />
									
									<div v-if="checkbox.actions.length"
									     class="product-actions">
										<template v-for="action in checkbox.actions">
											<acquisit-button :label="action.label"
											                 type="text"
											                 class="product-action"
											                 color="lightgrey"
											                 :no-interact="true"
											                 :display-only="true" />
										</template>
									</div>
								</div>
							</div>
							
							<template v-if="page.product.terms?.checkbox_label">
								<br />
								<acquisit-checkbox v-model="page.product.terms_agreed"
								                   no-interact display-only
								                   :color="page.product.color || null"
								                   :label="page.product.terms.checkbox_label" />
							</template>
						</template>
						
						<template v-else-if="page.declined_label">
							<acquisit-span class="centered-label" :label="page.declined_label" />
						</template>
						
						<template v-else-if="page.already_ordered_label">
							<acquisit-span class="centered-label" :label="page.already_ordered_label" />
						</template>
					</template>
					
					<template v-else>
						<acquisit-components-container display-only
						                               no-interact
						                               :components="page.components" />
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import type { Component, UILabelOptional, Page, Product } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { useBaseComponentProps, useBaseComponentEmits, useBaseComponent } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import { clone, pageHasSemantic, forEachSlotOfComponent } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	import { useProtocolStore } from '@/stores/protocol'
	import { useProductsStore } from '@/stores/products'
	import { usePersonsStore } from '@/stores/persons'
	import { computed, type PropType } from 'vue'
	
	interface NormalizedPage extends Page {
		person_collection?: Component
		declined_label?: UILabelOptional
		already_ordered_label?: UILabelOptional
		product?: Product
	}
	
	const props = defineProps({
		...useBaseComponentProps(),
		
		header: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false
		}
	})
	
	const emit = defineEmits([
		...useBaseComponentEmits()
	])
	
	const base = useBaseComponent(props, emit)
	const { color, language } = base
	
	const protocolStore = useProtocolStore()
	const personsStore = usePersonsStore()
	const productsStore = useProductsStore()
	
	const pagesNormalized = computed(() => {
		let normalized: NormalizedPage[] = []
		
		for (let page of protocolStore.pages) {
			let normalizedPage: NormalizedPage = { ...page }
			let ignorePage = !page.enabled
			
			if (pageHasSemantic(page, 'persons')) {
				normalizedPage.person_collection = findComponent(page, 'persons-editor') ?? undefined
				normalizedPage.components = page.components.filter(c => c.tag_normalized != 'persons-editor')
			} else if (pageHasSemantic(page, 'product')) {
				let productChooserCarousel = findComponent(page, 'single-product-chooser-carousel'),
					productChooserList = findComponent(page, 'single-product-chooser-list')
				
				let findSelectedProduct = chooser => {
					if (chooser.properties.declined || !page.enabled) {
						return {
							declined: true,
							label: chooser.properties.decline_button_text
						}
					} else if (chooser.properties.already_ordered) {
						return {
							alreadyOrdered: true,
							label: 'Allerede bestilt'
						}
					} else {
						let selectedProductUID = chooser.properties.modelValue
						
						if (Array.isArray(selectedProductUID)) {
							return {
								product: productsStore.products[selectedProductUID[0]]
							}
						}
						
						return {}
					}
				}
				
				if (productChooserCarousel || productChooserList) {
					normalizedPage.components = []
					
					let { label, product, declined, alreadyOrdered } = findSelectedProduct(productChooserCarousel || productChooserList)
					
					if (label) {
						if (declined) {
							normalizedPage.declined_label = label
						} else if (alreadyOrdered) {
							normalizedPage.already_ordered_label = label
						}
					} else if (product) {
						normalizedPage.product = product
					} else {
						// Nothing found, TODO handle?
					}
				}
			} else if (pageHasSemantic(page, ['signature', 'content-summary', 'summary'], 'OR')) {
				ignorePage = true
			} else {
				normalizedPage.components = filterComponents(normalizedPage.components, [ 'info-box', 'content-summary' ])
			}
			
			if (!ignorePage) {
				normalized.push(normalizedPage)
			}
		}
		
		return normalized
	})
	
	const filterComponents = (components: Component[], blockedTags: string[] = []) => {
		let filtered: Component[] = []
		
		for (let component of components) {
			if (blockedTags.indexOf(component.tag_normalized) === -1) {
				if (component.children) {
					component = clone(component)
					
					forEachSlotOfComponent(component, (children, slot) => {
						component.children[slot] = filterComponents(children, blockedTags)
					})
				}
				
				filtered.push(component)
			}
		}
		
		return filtered
	}
	
	const findComponent = (page: Page, normalizedTag: string) => {
		for (let component of page.components) {
			if (component.tag_normalized == normalizedTag) {
				return component
			}
		}
		
		return null
	}
	
	const getSemanticClassesForPageContent = (page: Page) => {
		return page.data.semantic?.map(s => 'semantic-' + s) ?? []
	}
	
	const pageTitleStyle = computed(() => ({
		color: color.baseVisuallySafe.value.cssHSLA
	}))
	
	defineExpose({
		...base.expose
	})
</script>

<style lang="scss" scoped>
	@use '@/assets/mixins.scss' as m;
	
	.acquisit-content-summary {
	
		.header {
			@include m.main-font(1rem, 600);
			text-align: center;
			color: m.color("light-grey-blue");
			letter-spacing: 3px;
			text-transform: uppercase;
		}
		
		.centered-label {
			text-align: center;
		}
		
		.page-title {
			@include m.main-font(1.27rem, 500);
			
			padding: {
				top: 32px;
				bottom: 12px;
			}
			
			background: #fff;
			color: m.color("dark");
		}
		
		.page-content {
			padding: {
				top: 16px;
				bottom: 16px;
				left: 20px;
				right: 20px;
			}
			
			border: 1px solid m.color("border");
			border-radius: 6px;
			
			.product-checkboxes {
				margin-top: 16px;
				
				.product-checkbox {
					
					.product-actions {
						padding-left: 38px;
					}
					
					.product-action + .product-action {
						margin-left: 8px;
					}
				}
				
				.product-checkbox + .product-checkbox {
					margin-top: 8px;
				}
			}
			
			&.semantic-persons {
				padding: {
					top: 20px;
					bottom: 20px;
				}
				
				.persons-type {
					display: flex;
					
					label {
						display: block;
						width: 33%;
						max-width: 96px;
						font-weight: 600;
					}
					
					& + div {
						margin-top: 30px;
					}
					
					& + .persons-type {
						margin-top: 8px;
					}
				}
			}
		}
		
		@include m.media(mobile) {
			
			.page-title {
				color: m.color("light-grey-blue");
				text-align: center;
				border-top: 16px solid m.color("light-background");
				border-bottom: 0px;
				
				padding: {
					top: 20px;
					bottom: 20px;
					left: 24px;
					right: 24px;
				}
				
				margin: {
					left: -24px;
					right: -24px;
				}
			}
			
			.page-content {
				border: {
					left: 0;
					right: 0;
					bottom: 0;
				}
				
				padding: {
					left: 16px;
					right: 16px;
				}
				
				margin: {
					left: -24px;
					right: -24px;
				}
			}
			
			.content-summary-page:last-child .page-content {
				border-bottom: 16px solid m.color("light-background");
			}
		}
	}
</style>
