import { defineStore } from 'pinia'
import { cast } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import { DateTimeExt, VERSION as ACQUISIT_UI_VERSION } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import type { Config } from '@/config.default.ts'

export enum Origin {
	CLASSIC = 'classic',
	UI_BUILDER = 'uib'
}

export interface GenericStoreState {
	backup_enabled: boolean
	build: string
	ui: string
	origin: Origin
	next_maintenance_start: DateTimeExt | undefined
	
	dev: {
		submission_payload_validated_only: boolean
		menu_open: boolean
	}
}

export const useGenericStore = defineStore('generic', {
	state: () => cast<GenericStoreState>({
		backup_enabled: !import.meta.env.DEV,
		build: import.meta.env.BUILD,
		ui: ACQUISIT_UI_VERSION,
		origin: Origin.CLASSIC,
		next_maintenance_start: undefined,
		
		dev: {
			submission_payload_validated_only: true,
			menu_open: false
		},
	}),
	
	getters: {
		is_origin_ui_builder: state => state.origin === Origin.UI_BUILDER,
		is_origin_classic: state => state.origin === Origin.CLASSIC,
	},
	
	actions: {
		setDevSubmissionPayloadValidatedOnly(state: boolean) {
			this.dev.submission_payload_validated_only = state
		},
		
		setDevMenuOpen(state: boolean) {
			this.dev.menu_open = state
		},
		
		setBackupEnabled(state: boolean) {
			this.backup_enabled = state
		},
		
		setOrigin(origin: Origin) {
			this.origin = origin
		},
		
		async checkForUpdates() {
			try {
				const response = await fetch('/latestVersion.json')
				
				if (response.status >= 400) {
					console.error('Error checking for updates', response)
					return false
				}
				
				const data = await response.json()
				
				if (data.build && data.build > import.meta.env.BUILD_NUMERIC) {
					console.info('Update', data.version, 'available')
					return true
				}
			} catch (e: any) {
				console.error('Error checking for updates', e)
			}
			
			return false
		},
		
		async checkForMaintenance() {
			try {
				const response = await fetch('/maintenance.json')
				
				if (response.status >= 400) {
					console.info('No maintenance data available', response)
					return false
				}
				
				const data = (await response.json()) as Config['maintenance']
				const now = DateTimeExt.now().getTimestamp()
				
				if (data?.message) {
					if (data.start) {
						this.next_maintenance_start = DateTimeExt.fromTimestamp(data.start)
						
						if (data.start < now) {
							// Maintenance has started
							console.info('Maintenance has started', '<')
							return true
						}
						
						// Maintenance is due soon, not yet
						return false
					} else {
						// Maintenance has started
						console.info('Maintenance has started', 'now')
						this.next_maintenance_start = DateTimeExt.now()
						return true
					}
				} else {
					// No maintenance
					console.info('No upcoming maintenance')
					this.next_maintenance_start = undefined
					return false
				}
			} catch (e: any) {
				console.error('Error checking for maintenance', e)
			}
		}
	}
})