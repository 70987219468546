<!--
	@author bluefirex
-->
<template>
	<div :class="['acquisit-toast', 'type-' + type, { 'is-open': isOpen }]">
		<div class="toast">
			<div class="meta">
				<span class="title">
					{{ title }}
				</span>
				
				<span class="content">
					{{ content }}
				</span>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { useBaseComponentProps, useBaseComponentEmits, useBaseComponent } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import { ref } from 'vue'
	
	const props = defineProps({
		...useBaseComponentProps(),
		
		title: {
			type: String,
			required: false,
		},
		
		content: {
			type: String,
			required: false,
		},
		
		type: {
			type: String,
			required: false,
			default: 'info'
		}
	})
	
	const emit = defineEmits([
		...useBaseComponentEmits(),
		'open',
		'close'
	])
	
	const base = useBaseComponent(props, emit)
	const { color } = base
	
	const isOpen = ref<boolean>(false),
		openTimer = ref<any>()
	
	const clearTimer = () => {
		if (openTimer.value !== undefined) {
			window.clearTimeout(openTimer.value)
		}
	}
	
	const open = (timer?: number) => {
		clearTimer()
		isOpen.value = true
		
		if (timer !== undefined) {
			openTimer.value = window.setTimeout(() => isOpen.value = false, timer)
		}
	}
	
	const close = () => {
		clearTimer()
		isOpen.value = false
	}
	
	defineExpose({
		...base.expose
	})
</script>

<style lang="scss">
	@use '@/assets/mixins.scss' as m;
	
	.acquisit-toast {
		box-sizing: border-box;
		position: fixed;
		top: 8px;
		left: 0;
		width: 100%;
		pointer-events: none;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1.5;
		
		opacity: 0;
		transform: translateY(-100%);
		transition: opacity 0.3s, transform 0.3s ease;
		
		.toast {
			@include m.box-elevated-shadow;
			background: #333;
			max-width: 640px;
			padding: 12px 16px;
			border-radius: 6px;
			
			.title {
				display: block;
				font-weight: 600;
			}
		}
		
		&.type-success {
			
			.toast {
				background: m.color("green");
			}
		}
		
		&.is-open {
			opacity: 1;
			transform: translateY(0);
		}
	}
</style>