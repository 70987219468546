<template>
	<a :class="classes" tabindex="0" @keypress.enter="onClick" role="button" :aria-label="ariaLabelString" :aria-labelledby="label && !ariaLabel ? uid + '-label' : undefined" ref="$root">
		<transition name="fade">
			<div :style="style.wrapper" :class="['wrapper', type + '-wrapper', iconPosition, { disabled }]" @click="onClick" tabindex="-1" role="none">
				<transition name="fade" mode="out-in">
					<acquisit-spinner class="spinner" size="tiny" v-if="loading" :color="colorSpinner" key="spinner" />
					<v-svg :style="style.icon" class="icon" v-else-if="icon" :file="icon" aria-hidden="true" key="icon" />
				</transition>
				
				<div class="content-wrapper">
					<acquisit-string class="content" :id="uid + '-label'" v-if="label" :source="label" />
				
					<template v-else>
						<slot class="content"></slot>
					</template>
				</div>

				<div class="ripple-container" v-if="!noInteract" aria-hidden="true">
					<transition-group name="ripple" @before-enter="ripple.onBeforeEnter" @after-enter="ripple.clearLast" appear>
						<span v-for="(r) in ripple.ripples.value" class="ripple" :key="r" :style="ripple.style"></span>
					</transition-group>
				</div>
				
				<div class="hover-background" v-if="type == 'raised' || type == 'action' || type == 'flat'" :style="style.hover"></div>
				
				<div class="pulse-wrapper" v-if="errors">
					<div class="pulse" :style="style.pulse"></div>
				</div>
				
				<div class="focus-ring" :style="{ borderColor: colorFocus.cssHSLA }"></div>
			</div>
		</transition>
	</a>
</template>

<script setup lang="ts">
	import type { PropType } from 'vue'
	import { computed, ref } from 'vue'
	import type { UILabelOptional } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { useBaseComponentProps, useBaseComponentEmits, useBaseComponent, useRipple, Color } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	
	const props = defineProps({
		...useBaseComponentProps(),
		
		label: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: undefined
		},
		
		ariaLabel: {
			type: [String, Object] as PropType<UILabelOptional>,
			required: false,
			default: undefined
		},
		
		size: {
			type: Number,
			required: false,
			default: 32
		},
		
		iconSize: {
			type: Number,
			required: false,
			default: 24
		},
		
		iconPosition: {
			type: String,
			required: false,
			default: 'left'
		},
		
		effect: {
			type: String,
			required: false,
			default: 'ripple'
		},
		
		bordered: {
			type: Boolean,
			required: false,
			default: false
		},
		
		bold: {
			type: Boolean,
			required: false,
			default: false,
		},
		
		underlined: {
			type: Boolean,
			required: false,
			default: false
		},
		
		// Options: raised, flat, text, action, icon
		type: {
			type: String,
			required: false,
			default: undefined
		},
		
		icon: {
			type: String,
			required: false,
			default: undefined
		},
		
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		
		loading: {
			type: Boolean,
			required: false,
			default: false
		}
	})
	
	const emit = defineEmits([
		...useBaseComponentEmits()
	])
	
	const base = useBaseComponent(props, emit)
	const { color, language, guards } = base
	
	const $root = ref<HTMLElement|undefined>()
	const ripple = useRipple($root)
	
	type Style = Record<any, any>
	
	interface ButtonTheme {
		wrapper: Style
		hover: Style
		ripple: Style
		pulse: Style
		icon: Style
	}
	
	// Classes
	const classes = computed(() => [
		'acquisit-button',
		'type-' + props.type,
		'theme-' + props.theme,
		{
			'no-interact': (props as any).noInteract,
			bordered: props.bordered,
			bold: props.bold,
			underlined: props.underlined,
			'has-icon': props.icon,
			disabled: props.disabled,
			error: (props as any).errors,
			erroring: (props as any).erroring || (props as any).hasCurrentErrors
		}
	])
	
	const ariaLabelString = computed(() => language.parse(props.ariaLabel as UILabelOptional) as any)
	
	// Behavior
	const onClick = (e) => {
		if (props.noInteract) {
			return
		}
		
		guards.emit('clicked')
		
		if (e.type == 'click' && !props.disabled) {
			ripple.click(e)
		}
	}
	
	const focus = () => {
		$root.value?.focus()
	}
	
	// Styling
	const colorFocus = computed((): Color => {
		if (props.type == 'raised') {
			if (color.base.value.perceivedBrightness < 230) {
				return color.focusLight.value
			}
		}
		
		return color.focusDark.value
	})
	
	const colorSpinner = computed((): Color => {
		switch (props.type) {
			case 'raised':
			case 'action':
				if (props.disabled) {
					return color.backgroundLightTinted.value
				}
				
				const baseColor = color.base.value
				
				if (baseColor.perceivedBrightness > color.PERCEIVED_BRIGHTNESS_THRESHOLD) {
					return baseColor.darken(20)
				}
				
				return color.white
			
			case 'flat':
			case 'text':
			case 'icon':
				if (props.disabled) {
					return color.backgroundLightTinted.value
				}
				
				break
		}
		
		return color.baseVisuallySafe.value
	})
	
	const style = computed((): ButtonTheme => {
		let hoverColor,
			wrapper: Style = {},
			hover: Style = {},
			ripple: Style = {},
			pulse: Style = {},
			icon: Style = {}
		
		switch (props.type) {
			/**
			 * Material Raised, colored background
			 */
			case 'raised':
				let alphaMultiplier = color.base.value.alpha || 1
				
				hoverColor = color.base.value.isLight() ?
				             color.base.value.darken(10) :
				             color.base.value.lighten(10).desaturate(8)
				
				wrapper = {
					background: color.base.value.cssHSLA,
					color: color.textContrast.value.cssHSLA,
					boxShadow: `0px 2px 4px ${color.base.value.withAlpha(0.3 * alphaMultiplier).cssHSLA},
										0px 2px 5px ${color.base.value.darken(16).withAlpha(0.2 * alphaMultiplier).cssHSLA}`
				}
				
				if (props.disabled) {
					const textColor = color.backgroundLightTinted.value.clone()
					textColor.hsl.l = 0.67
					
					wrapper.background = color.backgroundLightTinted.value.cssHSLA
					wrapper.color = textColor
					delete wrapper.boxShadow
				}
				
				hover = {
					background: hoverColor.cssHSLA
				}
				
				ripple = {
					background: color.textContrast.value.cssHSLA
				}
				
				pulse = {
					borderColor: wrapper.background
				}
				
				break
			
			/**
			 * Material Flat, no background
			 */
			case 'flat':
				wrapper = {
					color: props.disabled ?
					       '#777' :
					       color.baseVisuallySafe.value.cssHSLA
				}
				
				hover = {
					background: props.theme == 'dark' ?
					            color.base.value.withAlpha(0.16).cssHSLA :
					            color.base.value.withAlpha(0.08).cssHSLA
				}
				
				ripple = {
					background: color.base.value.cssHSLA
				}
				
				pulse = {
					borderColor: wrapper.color
				}
				
				break
			
			/**
			 * Icon-only
			 */
			case 'icon':
				wrapper = {
					color: color.base.value.cssHSLA,
					width: props.size + 'px',
					height: props.size + 'px'
				}
				
				hover = {
					background: props.theme == 'dark' ?
					            color.base.value.withAlpha(0.16).cssHSLA :
					            color.base.value.withAlpha(0.08).cssHSLA
				}
				
				ripple = {
					background: color.base.value.cssHSLA
				}
				
				pulse = {
					borderColor: wrapper.color
				}
				
				icon = {
					width: props.iconSize + 'px',
					height: props.iconSize + 'px'
				}
				
				break
			
			case 'action':
				hoverColor = color.base.value.isLight() ?
				             color.base.value.darken(10) :
				             color.base.value.lighten(12).desaturate(8)
				
				wrapper = {
					background: color.base.value.cssHSLA,
					color: color.textContrast.value.cssHSLA,
					width: props.size + 'px',
					height: props.size + 'px'
				}
				
				icon = {
					width: props.iconSize + 'px',
					height: props.iconSize + 'px'
				}
				
				hover = {
					background: hoverColor.cssHSLA
				}
				
				ripple = {
					background: color.base.value.cssHSLA
				}
				
				pulse = {
					borderColor: wrapper.color
				}
				
				if (props.bordered) {
					delete wrapper.background
					wrapper.borderColor = color.base.value.cssHSLA
					wrapper.color = color.base.value.cssHSLA
					
					hover.background = props.theme == 'dark' ?
					                   color.base.value.withAlpha(0.16).cssHSLA :
					                   color.base.value.withAlpha(0.08).cssHSLA
				}
				
				if (props.disabled) {
					let textColor = color.backgroundLightTinted.value.clone()
					textColor.hsl.l = 0.67
					
					wrapper = {
						background: color.backgroundLightTinted.value.cssHSLA,
						color: textColor
					}
				}
				
				break
			
			case 'text':
				wrapper = {
					color: color.baseVisuallySafe.value.cssHSLA,
					textDecoration: 'underline !important'
				}
				
				ripple = {
					background: color.base.value.cssHSLA
				}
				
				pulse = {
					borerColor: wrapper.color
				}
				
				break
		}
		
		return {
			wrapper,
			hover,
			ripple,
			pulse,
			icon
		}
	})
	
	defineExpose({
		...base.expose,
		focus
	})
</script>

<style lang="scss">
	@use 'sass:color';
	@use '@/assets/mixins.scss' as m;
	
	.acquisit-button {
		display: inline-block;
		vertical-align: middle;
		font-size: 1rem;
		
		&, a {
			-webkit-tap-highlight-color: transparent;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			position: relative;
			touch-action: manipulation;
		}
		
		.wrapper {
			cursor: pointer;
			position: relative;
			transition: background 0.2s;
			
			&:focus {
				outline: 0;
			}
		}
		
		.focus-ring {
			@include m.focus-ring;
		}
		
		&:focus {
			outline: 0;
			
			.focus-ring {
				@include m.focus-ring-visible(0.5);
			}
		}
		
		.icon,
		.content-wrapper,
		.spinner {
			position: relative;
			z-index: 2;
		}
		
		.hover-background {
			z-index: 1;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			opacity: 0;
			transition: opacity 0.2s, background 0.2s;
			border-radius: 6px;
		}
		
		@media (hover: hover) {
			&:hover {
				
				.hover-background {
					opacity: 1;
				}
			}
		}
		
		.raised-wrapper {
			display: flex;
			font-weight: 600;
			align-items: center;
			justify-content: center;
			border-radius: 6px;
			padding: 12px 24px;
			min-width: 36px;
			position: relative;
			
			.icon {
				width: 20px;
				height: 20px;
			}
			
			&,
			&.left {
				flex-direction: row;
				
				.icon, .spinner {
					margin: 0px 10px 0px -2px;
				}
			}
			
			&.right {
				flex-direction: row-reverse;
				
				.icon, .spinner {
					margin: 0px -8px 0 12px;
				}
			}
			
			&.top {
				flex-direction: column;
				
				.icon, .spinner {
					margin: 4px 0px 8px 0px;
				}
			}
			
			.focus-ring {
				top: 4px;
				bottom: 4px;
				left: 4px;
				right: 4px;
				border-radius: 3px;
			}
			
			.ripple-container {
				z-index: 3;
			}
			
			.pulse {
				border-radius: 6px;
			}
		}
		
		&:focus .raised-wrapper .focus-ring {
			opacity: 0.67;
		}
		
		.text-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 1.3;
			
			.icon {
				width: 18px;
				height: 18px;
			}
			
			&,
			&.left {
				flex-direction: row;
				
				.icon, .spinner {
					margin-right: 6px;
				}
			}
			
			&.right {
				flex-direction: row-reverse;
				
				.icon, .spinner {
					margin-left: 6px;
				}
			}
			
			&.top {
				flex-direction: column;
				
				.icon, .spinner {
					margin-bottom: 6px;
				}
			}
			
			&.bottom {
				flex-direction: column-reverse;
				
				.icon, .spinner {
					margin-top: 6px;
				}
			}
			
			.focus-ring {
				top: -4px;
				bottom: -4px;
				left: -8px;
				right: -8px;
			}
			
			.pulse {
				border-radius: 6px;
			}
			
			&.disabled {
				color: m.color("light-grey") !important;
			}
		}
		
		.icon-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			transition: background 0.2s;
			
			border-radius: 300px;
			
			padding: {
				left: 6px;
				right: 6px;
				top: 6px;
				bottom: 6px;
			}
			
			.pulse {
				border-radius: 50%;
				animation-name: button-pulse-uniform;
			}
			
			@media (hover: hover) {
				&:hover {
					background: rgba(#000, 0.04);
				}
			}
		}
		
		&.bordered {
			transition: border 0.2s, color 0.2s;
			
			.icon-wrapper,
			.flat-wrapper {
				border: 1px solid m.color("border");
				border-radius: 6px;
			}
			
			.flat-wrapper {
				padding: {
					top: 12px;
					bottom: 12px;
					left: 20px;
					right: 20px;
				}
				
				&.left .icon,
				&.left .spinner {
					margin: {
						left: 0px;
						top: -1px;
					}
				}
				
				&.disabled {
					filter: grayscale(100%);
					
					.hover-background {
						display: none;
					}
				}
			}
			
			.icon-wrapper {
				
				&.disabled {
					// filter: grayscale(100%);
					opacity: 0.4;
					pointer-events: none;
					
					color: m.color("light-grey") !important;
					
					.hover-background {
						display: none;
					}
				}
			}
			
			.action-wrapper {
				border: 1px solid m.color("border");
			}
			
			.focus-ring {
				border-radius: 3px;
			}
			
			&.theme-dark {
				
				.icon-wrapper,
				.flat-wrapper {
					border-color: m.color("dark-border");
				}
			}
		}
		
		&.underlined {
			
			.wrapper {
				text-decoration: underline !important;
			}
		}
		
		&.bold {
			
			.content-wrapper {
				font-weight: 700 !important;
			}
		}
		
		.action-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100px;
			width: 32px;
			height: 32px;
			
			.icon {
				height: 16px;
				width: 16px;
			}
			
			.focus-ring {
				border-radius: 50%;
				
				top: -6px;
				left: -6px;
				right: -6px;
				bottom: -6px;
			}
			
			.hover-background {
				border-radius: 50%;
			}
			
			.pulse {
				border-radius: 100px;
				animation-name: button-pulse-uniform;
			}
		}
		
		.flat-wrapper {
			height: inherit;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 12px 24px;
			font-size: 14px;
			transition: background 0.2s;
			
			.icon {
				width: 20px;
				height: 20px;
			}
			
			&.top {
				flex-direction: column;
				text-align: center;
				
				.icon, .spinner {
					margin: 0px 0px 12px 0px;
				}
			}
			
			&.left {
				flex-direction: row;
				
				.icon, .spinner {
					margin-right: 12px;
					margin-left: -6px;
				}
			}
			
			&.right {
				flex-direction: row-reverse;
				
				.icon, .spinner {
					margin-left: 12px;
					margin-right: -6px;
				}
			}
			
			.pulse {
				border-radius: 6px;
			}
		}
		
		/*&:not(.has-icon) .raised-wrapper {
			padding: {
				top: 12px;
				bottom: 12px;
			}
		}*/
		
		.pulse-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 3;
			pointer-events: none;
			
			.pulse {
				box-sizing: border-box;
				
				position: absolute;
				top: 0;
				left: 0;
				
				width: 100%;
				height: 100%;
				
				border: 2px solid transparent;
				
				// animation-play-state: running;
				animation-name: button-pulse;
				animation-duration: 1.5s;
				animation-iteration-count: infinite;
			}
			
			@keyframes button-pulse {
				0% {
					transform: scale(1.05, 1.15);
					opacity: 0;
				}
				
				40% {
					transform: scale(1.1, 1.3);
					opacity: 0.8;
				}
				
				100% {
					transform: scale(1.3, 1.5);
					opacity: 0;
				}
			}
			
			@keyframes button-pulse-uniform {
				0% {
					transform: scale(1.05);
					opacity: 0;
				}
				
				40% {
					transform: scale(1.2);
					opacity: 0.8;
				}
				
				100% {
					transform: scale(1.5);
					opacity: 0;
				}
			}
		}
		
		&.erroring {
			
			.action-wrapper {
				background: m.color("red") !important;
			}
			
			.raised-wrapper {
				background: m.color("red") !important;
				
				box-shadow: 0px 2px 4px rgba(m.color("red"), 0.3),
							0px 2px 5px rgba(color.adjust(m.color("red"), $lightness: -16%, $space: hsl), 0.2) !important;
			}
			
			.icon-wrapper {
				color: m.color("red") !important;
			}
		}
		
		&.error {
			
			.text-wrapper {
				color: m.color("red") !important;
			}
		}
		
		&.disabled .wrapper,
		&.no-interact .wrapper {
			cursor: default;
			
			.hover-background {
				display: none;
			}
		}
	}
	
	// if displayed as a standalone component, margin is required
	.acquisit-button.acquisit-component + .acquisit-button {
		margin-left: 24px;
	}
	
	.acquisit-info-box + .acquisit-button {
		margin-top: 24px;
	}
	
	.acquisit-span + .acquisit-button.acquisit-component {
		margin-top: 20px;
		
		&.type-text {
			margin-top: 0;
		}
	}
	
	.acquisit-span:not(.space-bottom) + .acquisit-button.acquisit-component {
		margin-top: -8px;
	}
	
	.acquisit-input + .acquisit-button {
		margin-top: 20px;
	}
</style>
