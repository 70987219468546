<template>
	<div class="acquisit-signature-session-decoder default-wrapper">
		<StateInspectorHeader class="header">
			<template #default>
				<h2>bankID Decoder</h2>
			</template>
			
			<template #actions>
			
			</template>
		</StateInspectorHeader>
	</div>
	
	<div class="form">
		<acquisit-textarea v-model="urlString"
		                   theme="dark"
		                   color="blue"
		                   label="URL" />
	</div>
	
	<div class="result">
		<div class="row">
			<label>token</label>
			<div class="value token">{{ token ?? '–' }}</div>
		</div>
		
		<div class="row">
			<label>bid</label>
			<div class="value bid">{{ bid ?? '–' }}</div>
		</div>
		
		<div class="row">
			<label>sessionId</label>
			<div class="value session-id">{{ sessionID ?? '–' }}</div>
		</div>
		
		<div class="row">
			<label>status</label>
			<div :class="['value', 'status-' + status]">{{ status ?? '–' }}</div>
		</div>
		
		<div class="row">
			<label>Decoded</label>
			<div :class="['value', 'decoded', { error: decoded == '- invalid -' }]">{{ decoded }}</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import StateInspectorHeader from '@ui/dev/StateInspectorHeader.vue'
	import { decryptAES_GCM } from '@/lib/functions/crypto'
	import { computed, ref, watch } from 'vue'
	import { debounce } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
	
	const urlString = ref(window.location.href)
	const url = computed(() => {
		try {
			return new URL(urlString.value)
		} catch (e) {
			// cry
			return null
		}
	})
	
	const bid = computed(() => {
		return url.value?.searchParams.get('bid')
	})
	
	const sessionID = computed(() => {
		return url.value?.searchParams.get('sessionId')
	})
	
	const status = computed(() => {
		return url.value?.searchParams.get('status')
	})
	
	const token = computed(() => {
		const path = String(url.value?.pathname ?? '').split('/')
		
		if (path.length >= 3 && path[1] == 'p') {
			return path[2]
		}
		
		return null
	})
	
	const decoded = ref('–')
	
	const decode = async () => {
		if (!bid.value) {
			return '?'
		}
		
		const password = (token.value ?? 'default').substring(0, 7)
		const decrypted = (await decryptAES_GCM(bid.value, password))
		
		if (decrypted) {
			return JSON.stringify(JSON.parse(decrypted), null, 4)
		}
		
		return '?'
	}
	
	watch(bid, debounce(async () => {
		try {
			decoded.value = (await decode()) ?? '?'
		} catch (e) {
			decoded.value = '- invalid -'
		}
	}, 300), {
		immediate: true
	})
</script>

<style lang="scss">
	@use '@/assets/mixins.scss' as m;
	
	.form {
		padding: 0px 24px;
		
		.acquisit-textarea {
			flex: 1;
			
			textarea {
				background: transparent;
				color: #fff;
				
				&:not(:focus, :hover) {
					border-color: rgba(#fff, 0.2);
				}
			}
			
			.label {
				background: #000;
			}
		}
	}
	
	.result {
		padding: 20px 24px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		
		.row {
			display: flex;
			align-items: baseline;
			
			border: 1px solid rgba(#fff, 0.2);
			border-radius: 6px;
			padding: 16px;
			
			> label {
				width: 15%;
				font-weight: 600;
				text-align: right;
				box-sizing: border-box;
				padding-right: 20px;
			}
			
			.value {
				box-sizing: border-box;
				width: 85%;
				
				font-family: m.font(monospace);
				font-size: 0.9em;
				
				&.bid {
					color: m.color("teal");
				}
				
				&.token {
					color: m.color("lime");
				}
				
				&.session-id {
					color: m.color("green");
				}
				
				&.error {
					color: m.color("red");
				}
				
				&.status-success {
					color: m.color("green");
				}
				
				&.status-abort {
					color: m.color("orange");
				}
				
				&.status-error {
					color: m.color("red");
				}
				
				&.bid, &.token {
					word-wrap: break-word;
					user-select: all;
				}
				
				&.decoded {
					display: block;
					unicode-bidi: embed;
					font-family: monospace;
					white-space: pre;
					line-height: 1.5;
				}
			}
		}
	}
</style>