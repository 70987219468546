<!--
    @author bluefirex
    @date 24.09.21
-->
<template>
	<address :class="['acquisit-address-label', 'align-' + align, { bold }]">
		<span :class="['address', { big: big == 'address' }]">{{ address }}</span>
		
		<span :class="['postcode-city', { big: big == 'postcode-city', secondary: address }]" v-if="postcode || city">
			<span class="postcode" v-if="postcode">{{ postcode }}</span>
			<span class="city" v-if="city">{{ city }}</span>
		</span>
		
		<span :class="['cadaster']" v-if="cadaster">{{ cadasterFormatted }}</span>
	</address>
</template>

<script setup lang="ts">
	import { useBaseComponentProps, useBaseComponentEmits, useBaseComponent } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/helpers'
	import { computed, type PropType } from 'vue'
	
	type CadasterPair = [string, string]
	
	const props = defineProps({
		...useBaseComponentProps(),
		
		address: {
			type: String,
			required: false,
			default: null
		},
		
		postcode: {
			type: String,
			required: false,
			default: null
		},
		
		city: {
			type: String,
			required: false,
			default: null
		},
		
		cadaster: {
			type: Array as PropType<CadasterPair[]>,
			required: false,
			default: null
		},
		
		align: {
			type: String,
			required: false,
			default: 'left'
		},
		
		highlight: {
			type: Boolean,
			required: false,
			default: true
		},
		
		bold: {
			type: Boolean,
			required: false,
			default: false
		}
	})
	
	const emit = defineEmits([
		...useBaseComponentEmits()
	])
	
	const base = useBaseComponent(props, emit)
	
	const big = computed(() => {
		if (!props.highlight) {
			return null
		}
		
		if (props.address) {
			return 'address'
		}
		
		if (props.postcode || props.city) {
			return 'postcode-city'
		}
		
		return null
	})
	
	const cadasterFormatted = () => {
		if (!props.cadaster) {
			return null
		}
		
		let parts: string[] = []
		
		for (let pair of props.cadaster) {
			parts.push(pair[0] + ': ' + pair[1])
		}
		
		return parts.join(' | ')
	}
	
	defineExpose({
		...base.expose
	})
</script>

<style lang="scss">
	@use '@/assets/mixins.scss' as m;
	
	.acquisit-address-label {
		display: block;
		line-height: 1.5;
		
		// background: color.adjust(m.color("light-background"), $lightness: -3%, $space: hsl);
		background: #fff;
		border: 1px solid m.color("border");
		border-radius: 4px;
		
		padding: {
			left: 16px;
			right: 16px;
			top: 12px;
			bottom: 12px;
		}
		
		&.align-center {
			text-align: center;
		}
		
		&.align-right {
			text-align: right;
		}
		
		> span {
			display: block;
			
			&.big {
				font-size: 1.2rem;
			}
			
			&.secondary {
				color: m.color("light-grey");
			}
			
			& + span {
				margin-top: 4px;
			}
		}
		
		&.bold > .address {
			font-weight: 600;
		}
	}
	
	.acquisit-span + .acquisit-address-label,
	.acquisit-address-label + .acquisit-span {
		margin-top: 16px !important;
	}
</style>
