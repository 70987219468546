<!--
	@author bluefirex
-->
<template>
	<div :class="['acquisit-state-inspector-available-import-export', { 'has-children': entry.children.length }]">
		<div class="entry">
			<div class="meta">
				<span class="uid">{{ entry.uid }}</span>
			</div>
			
			<div class="data-type" v-if="exp">
				<span class="type">{{ exp.dataType }}</span>
			</div>
			
			<div class="value" v-if="imp">
				<template v-if="typeof(imp.value) == 'object' && imp.value">
					<StateInspectorJsonView :json="imp.value" colored />
				</template>
				
				<template v-else>
					<pre>{{ imp.value ?? '–' }}</pre>
				</template>
			</div>
		</div>
		
		<div class="children" v-if="entry.children.length">
			<StateInspectorAvailableImportExport v-for="child in entry.children"
			                                     :entry="child" />
		</div>
	</div>
</template>

<script setup lang="ts">
	import { computed, type PropType } from 'vue'
	import type { ComponentValueAvailableExport, ComponentValueAvailableImport } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import { isComponentValueAvailableImport, isComponentValueAvailableExport } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
	import StateInspectorJsonView from '@ui/dev/StateInspectorJsonView.vue'
	
	const props = defineProps({
		entry: {
			type: Object as PropType<ComponentValueAvailableImport|ComponentValueAvailableExport>,
			required: true
		}
	})
	
	const imp = computed(() => {
		if (isComponentValueAvailableImport(props.entry)) {
			return props.entry
		}
		
		return undefined
	})
	
	const exp = computed(() => {
		if (isComponentValueAvailableExport(props.entry)) {
			return props.entry
		}
		
		return undefined
	})
</script>

<style lang="scss">
	@use '@/assets/mixins.scss' as m;
	
	$borderColor: rgba(#fff, 0.16);
	
	.acquisit-state-inspector-available-import-export {
		background: rgba(#fff, 0.05);
		border-radius: 10px;
		
		.entry {
			display: flex;
			gap: 16px;
			padding: 16px 16px;
			
			.meta {
				// flex: 1;
				flex: 1;
				
				.uid {
					font-weight: 600;
					user-select: all;
				}
			}
			
			.data-type {
				flex: 3;
				color: m.color("blue");
				
				span {
					font-family: m.font(monospace);
					font-size: 0.92rem;
				}
			}
			
			.value {
				flex: 3;
				user-select: all;
				
				pre {
					font-family: m.font(monospace);
					font-size: 0.92rem;
				}
			}
		}
		
		.children {
			padding-left: 16px;
			padding-right: 16px;
			padding-bottom: 16px;
			
			> .acquisit-state-inspector-available-import-export {
				
				&:first-child {
					border-top: 0px;
				}
				
				&:last-child {
					border-bottom: 0px;
				}
			}
		}
		
		& + .acquisit-state-inspector-available-import-export {
			margin-top: 16px;
		}
	}
</style>