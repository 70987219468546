import type { UILabel } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { labelFor } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'

export const formatSigningOnBehalfLabel = (label: UILabel, personName: string, onBehalfName: string|null, language?: string|null) => {
		label = String(labelFor(label, language))
		
		let formatted = label.replace(/{on_behalf.name}/g, `<span class="on-behalf-name">${onBehalfName}</span>`)
		                    .replace(/{person.name}/g, `<span class="on-behalf-person-name">${personName}</span>`)
		
		return `<span class="signing-on-behalf-description">${formatted}</span>`
	}