import type { Component, Backend as AcquisitUIBackend, Product, ImageCollection, InputImage, ProductActionCompound, ItemCompound } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import type { CreateImageCollectionResponse, CreateImageResponse } from '@/lib/backend/types'
import type Backend from '@/lib/backend/Backend'
import Asgard from '@/lib/backend/Asgard'
import Pylon from '@/lib/backend/Pylon'
import APIError from '@/lib/APIError'
import { Domain, DomainRouter } from '@/lib/domain-router'
import { useAPIStore } from '@/stores/api'
import Portal from '@/lib/backend/Portal'
import config from '@config'

// Figure out current backend
let currentBackend: Backend

const instances: Record<string, any> = {
	'asgard': null,
	'pylon': null,
	'portal': null
}

export const useAsgard = (): Asgard => {
	if (!instances.asgard) {
		instances.asgard = new Asgard()
	}
	
	return instances.asgard as Asgard
}

export const usePylon = (): Pylon => {
	if (!instances.pylon) {
		instances.pylon = new Pylon()
	}
	
	return instances.pylon as Pylon
}

export const usePortal = (): Portal => {
	if (!instances.portal) {
		instances.portal = new Portal()
	}
	
	return instances.portal as Portal
}

const detectBackend = (): Backend => {
	const currentDomain = DomainRouter.get()
	
	switch (currentDomain.id) {
		case Domain.DIGITALOVERTAKELSE:
		case Domain.DIGITALDOKUMENT:
		case Domain.OPPGJORSSKJEMA:
		case Domain.ENKLEREFLYTTING:
			return usePylon()
		
		default:
			// Check for preview, otherwise it's asgard
			if (window.location.pathname.substring(0, 8) == '/preview') {
				return usePortal()
			} else {
				return useAsgard()
			}
	}
}

switch (config.backend.override) {
	case 'asgard':
		currentBackend = useAsgard()
		break
	
	case 'pylon':
		currentBackend = usePylon()
		break
	
	default:
		// Auto-detect
		currentBackend = detectBackend()
}

export const useBackend = (): Backend => {
	return currentBackend
}

// Image uploads

const convertError = (backend: Backend, e: any) => {
	const parsed = backend.parseError(e)
	return new APIError(parsed.message, parsed.code || 0)
}

/**
 * Upload an image collection and convert any errors into an APIError
 *
 * @param {string}              token       API token
 * @param {string|null}         semantic    Image collection semantic
 * @param {Record<string, any>} metadata    Image collection metadata
 * @param {ImageCollection}     collection  The collection itself
 *
 * @returns {Promise<CreateImageCollectionResponse>}
 */
export const addImageCollection = async (token: string, semantic: string|null, metadata: Record<string, any>, collection: ImageCollection): Promise<CreateImageCollectionResponse> => {
	const backend = useBackend()
	
	try {
		return await backend.createImageCollection(token, collection.title, semantic, metadata)
	} catch (e: any) {
		throw convertError(backend, e)
	}
}

/**
 * Upload an image into a collection and convert any errors into an APIError
 *
 * @param {string}          token           API token
 * @param {string|number}   collectionID    Image Collection ID
 * @param {InputImage}      image           Image
 *
 * @returns {Promise<CreateImageResponse>}
 */
export const addImage = async (token: string, collectionID: string|number, image: InputImage): Promise<CreateImageResponse> => {
	const backend = useBackend()
	
	try {
		return await backend.uploadImage(token, collectionID, image.base64 as string, image.title ?? null)
	} catch (e: any) {
		throw convertError(backend, e)
	}
}

/**
 * Delete an image collection
 *
 * @param {string}              token           API token
 * @param {string|number}       collectionID    Image collection to delete
 * @returns {Promise<void>}
 */
export const deleteImageCollection = async (token: string, collectionID: string|number): Promise<void> => {
	const backend = useBackend()
	
	try {
		return await backend.deleteImageCollection(token, collectionID)
	} catch (e: any) {
		throw convertError(backend, e)
	}
}

/**
 * Delete an image
 *
 * @param {string}              token           API token
 * @param {string|number}       collectionID    Image collection of the image
 * @param {string|number}       imageID         Image to delete
 * @returns {Promise<void>}
 */
export const deleteImage = async (token: string, collectionID: string|number, imageID: string|number): Promise<void> => {
	const backend = useBackend()
	
	try {
		return await backend.deleteImage(token, collectionID, imageID)
	} catch (e: any) {
		throw convertError(backend, e)
	}
}

export const updateImageCollection = async (token: string, collectionID: string|number, metadata: Record<string, any> = {}): Promise<void> => {
	const backend = useBackend()
	
	try {
		return await backend.updateImageCollection(token, collectionID, null, metadata)
	} catch (e: any) {
		throw convertError(backend, e)
	}
}

/**
 * Upload and update image collections in the backend.
 * Returns a bunch of promises, use {@link Promise.all} to wait for all of them to be finished
 */
export const uploadImageCollections = (token: string, component: Component, item: ItemCompound, metadata: Record<string, any>, imageCollections: ImageCollection[]): Promise<any>[] => {
	let promises: Promise<any>[] = []

	for (let imageCollection of imageCollections) {
		// Check if deleted
		if (imageCollection.deleted) {
			// Delete image collection itself.
			let imageCollectionPromise = deleteImageCollection(token, imageCollection.hash_id!).then(() => {
				if (item) {
					if (item.image_collections) {
						for (let i = 0; i < item.image_collections.length; i++) {
							if (item.image_collections[i].id == imageCollection.id) {
								item.image_collections.splice(i, 1)
								break
							}
						}
					}
				} else if (Array.isArray(component.properties.modelValue)) {
					for (let i = 0; i < component.properties.modelValue.length; i++) {
						if (component.properties.modelValue[i].id == imageCollection.id) {
							component.properties.modelValue.splice(i, 1)
							break
						}
					}
				}
			})

			promises.push(imageCollectionPromise)
		} else {
			// Check if already uploaded
			if (!imageCollection.hash_id && imageCollection.images.length) {
				// Create Image Collection and its images
				const semantic = Array.isArray(component.properties.semantic) ?
				                 (component.properties.semantic[0] ?? null) :
				                 (component.properties.semantic ?? null)
				
				let imageCollectionPromise = addImageCollection(token, semantic, metadata, imageCollection).then(result => new Promise((resolve, reject) => {
					imageCollection.hash_id = result.hash_id

					let imagePromises = imageCollection.images
					                                   .filter(i => i.base64)
					                                   .map(image => addImage(token, imageCollection.hash_id!, image).then(result => {
						                                   image.hash_id = result.hash_id
						                                   image.url = result.url
						                                   image.base64 = null

						                                   return image
					                                   }))

					return Promise.all(imagePromises).then(resolve).catch(reject)
				}))

				promises.push(imageCollectionPromise)
			} else {
				let imageCount = imageCollection.images.length

				// Check whether the images were uploaded or deleted
				for (let image of imageCollection.images) {
					if (image.deleted) {
						let imagePromise = deleteImage(token, imageCollection.hash_id!, image.hash_id!).then(() => {
							for (let i = 0; i < imageCollection.images.length; i++) {
								if (imageCollection.images[i].id == image.id) {
									imageCollection.images.splice(i, 1)
									break
								}
							}
						})

						promises.push(imagePromise)
						imageCount--
					} else if (!image.hash_id) {
						let imagePromise = addImage(token, imageCollection.hash_id!, image).then(result => {
							image.hash_id = result.hash_id
							image.url = result.url
							image.base64 = null

							return image
						})

						promises.push(imagePromise)
						imageCount++
					}
				}

				/* // If we have 0 images now, we delete the collection for housekeeping
				if (imageCount <= 0) {
				    promises.push(deleteCollection(item, imageCollection))
				} */
			}
		}
	}

	return promises
}

export const createAcquisitUIBackendAdapter = (backend: Backend): AcquisitUIBackend => ({
	async getProductTerms(product: Product, _?: ProductActionCompound): Promise<string> {
		const terms = await backend.getProductTerms(useAPIStore().token!, product)
		
		return terms ?? ''
	},
	
	addImageCollection(collection: ImageCollection, metadata: Record<string, any>, semantic?: string | null): Promise<any> {
		return addImageCollection(useAPIStore().token!, semantic ?? null, metadata, collection)
	},
	
	addImage(collectionID: string | number, image: InputImage): Promise<any> {
		return addImage(useAPIStore().token!, collectionID, image)
	},
	
	updateImageCollection(collectionID: string | number, title: string | null, metadata: Record<string, any>): Promise<any> {
		return updateImageCollection(useAPIStore().token!, collectionID, metadata)
	},
	
	deleteImage(collectionID: string | number, image: string | number): Promise<void> {
		return deleteImage(useAPIStore().token!, collectionID, image)
	},
	
	deleteImageCollection(collectionID: string | number): Promise<void> {
		return deleteImageCollection(useAPIStore().token!, collectionID)
	},
})

export const useAcquisitUIBackendAdapter = () => createAcquisitUIBackendAdapter(useBackend())