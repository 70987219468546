import type { AnySignatureType, ParsedBankIDSignature, ParsedImageSignature, ParsedSignature, Signature } from '@/lib/types'
import type { ID, Person, PersonIDDictionary, PersonsAddressType } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { useLogger, isPerson, getRoleForType, PersonRole } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/library'
import { forEachObject, generateID, clone, cast, createRecord, sortPersons, normalizePerson } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/functions'
import { evaluateCondition, type Expression } from '@Visma-Real-Estate-Solutions/acquisit-ui-vue/expressions'
import { defineStore } from 'pinia'
import { useComponentsStore } from '@/stores/components'
import { useProtocolStore } from '@/stores/protocol'
import { useProductsStore } from '@/stores/products'
import { computed } from 'vue'

const log = useLogger('stores/persons', useLogger.COLOR_STORE)

/**
 * Parse a signature into its components
 *
 * @param signature Signature string in data URI-format
 *
 * @return {ParsedImageSignature|ParsedBankIDSignature|ParsedSignature}
 */
export const parseSignature = (signature: string|Record<string, any>|null): ParsedSignature|null => {
	if (!signature || signature == '@') {
		return null
	}
	
	let parts = String(signature).split(':'),
		type = parts[0]
	
	switch (type) {
		case 'bankid':
			let attributes = parts[1].split(';')
			
			return cast<ParsedBankIDSignature>({
				type,
				name: attributes[0],
				person_number: attributes[1],
				timestamp: Number(attributes[2])
			})
		
		case 'data':
			return cast<ParsedImageSignature>({
				type: 'image',
				datauri: signature as string
			})
		
		default:
			log.always.error('[persons/parseSignature] Unknown signature type')
			
			return cast<ParsedSignature>({
				type
			})
	}
}

const personMatchesConditions = (
	person: Person,
	personsConditions: Record<string, Expression>|undefined|null,
	allPersonsByID: Record<string, Person>,
) => {
	if (personsConditions?.[person.type]) {
		const condition = personsConditions[person.type]
		const context = {
			componentsByUID: useComponentsStore().by_uid,
			pagesByUID: useProtocolStore().pages_by_uid,
			productsByUID: useProductsStore().by_uid,
			personsByID: allPersonsByID,
			scope: {
				person
			}
		}
		
		return evaluateCondition(condition, context)
	}
	
	return true
}

export interface PersonsStoreState {
	persons: PersonIDDictionary
}

export const usePersonsStore = defineStore({
	id: 'persons',
	
	state: () => cast<PersonsStoreState>({
		persons: {}
	}),
	
	getters: {
		by_id: state => state.persons,
		
		/**
		 * All persons
		 */
		list: (state): Person[] => Object.values(state.persons).sort(sortPersons),
		
		/**
		 * All currently selected persons
		 */
		selected: (state): Person[] => {
			let selected: Person[] = []
			
			for (let id in state.persons) {
				if (state.persons.hasOwnProperty(id)) {
					let person = state.persons[id]
					
					if (person.selected) {
						selected.push(person)
					}
				}
			}
			
			return selected
		},
		
		/**
		 * Are all persons validated?
		 */
		all_validated(): boolean {
			for (let person of this.list) {
				if (!person.validated) {
					return false
				}
			}
			
			return true
		},
		
		/**
		 * Are all required persons validated?
		 */
		all_required_validated(): boolean {
			for (let person of this.required_list) {
				if (!person.validated) {
					return false
				}
			}
			
			return true
		},
		
		forID: state => (id: string | number) => state.persons[id] ?? null,
		forRole: state => (role: PersonRole) => Object.values<Person>(state.persons || {}).filter(p => p.role == role),
		forType: state => (type: string) => Object.values<Person>(state.persons || {}).filter(p => p.type == type),
		
		/**
		 * All persons of role seller
		 * @returns {Person[]}
		 */
		sellers(): Person[] {
			return this.forRole(PersonRole.SELLER)
		},
		
		/**
		 * All persons of role buyer
		 * @returns {Person[]}
		 */
		buyers(): Person[] {
			return this.forRole(PersonRole.BUYER)
		},
		
		/**
		 * All persons that need to have a signature to submit the protocol
		 * @returns {Person[]}
		 */
		required_list(): Person[] {
			const componentsStore = useComponentsStore()
			
			let signaturePageComponents = componentsStore.by_tag['signature'],
				signaturePageComponent = signaturePageComponents ? signaturePageComponents[0] : null
			
			if (!signaturePageComponent) {
				return this.list
			}
			
			const personsConditions = signaturePageComponent.properties.persons_conditions ?? {}
			
			// If there are no requirements set, require a signature from every type that has a label
			if (!signaturePageComponent.properties.required_signatures) {
				const labelledTypes = signaturePageComponent.properties.person_type_labels ?? {}
				
				if (!Object.keys(labelledTypes).length) {
					return this.list
				}
				
				return this.list.filter(p => {
					const isLabelledType = Boolean(labelledTypes[p.type])
					
					if (!isLabelledType) {
						return false
					}
					
					return personMatchesConditions(p, personsConditions, this.persons)
				})
			}
			
			const requiredTypes = Object.keys(signaturePageComponent.properties.required_signatures)
			
			return this.list.filter(p => {
				let isRequiredType = requiredTypes.indexOf(p.type) > -1
				
				if (!isRequiredType) {
					return false
				}
				
				return personMatchesConditions(p, personsConditions, this.persons)
			})
		},
		
		/**
		 * All persons that will be submitted
		 *
		 * @returns {Person[]}
		 */
		submission_list(): Person[] {
			const componentsStore = useComponentsStore()
			
			let signaturePageComponents = componentsStore.by_tag['signature'],
				signaturePageComponent = signaturePageComponents ? signaturePageComponents[0] : null
			
			if (!signaturePageComponent || !signaturePageComponent.properties.persons_conditions) {
				return this.list
			}
			
			let personsConditions = signaturePageComponent.properties.persons_conditions
			
			return this.list.filter(p => {
				if (personsConditions?.[p.type]) {
					let condition = personsConditions[p.type]
					let context = {
						componentsByUID: componentsStore.by_uid,
						pagesByUID: useProtocolStore().pages_by_uid,
						productsByUID: useProductsStore().by_uid,
						personsByID: this.persons,
						scope: {
							person: p
						}
					}
					
					return evaluateCondition(condition, context)
				}
				
				return true
			})
		},
		
		editable_list(): Person[] {
			const componentsStore = useComponentsStore()
			
			let personCollectionComponents = componentsStore.by_tag['persons-editor'],
				personCollectionComponent = personCollectionComponents ? personCollectionComponents[0] : null
			
			if (!personCollectionComponent?.properties?.types) {
				return []
			}
			
			let types = personCollectionComponent.properties.types
			
			return this.list.filter(p => types[p.type])
		}
	},
	
	actions: {
		/**
		 * Set all persons in the store
		 *
		 * @param {Person[]} persons
		 */
		set(persons: Person[]) {
			let normalized = persons.map<Person>(normalizePerson).sort(sortPersons)
			
			let ps: PersonIDDictionary = {}
			let index = 0
			
			for (let person of normalized) {
				ps[person.id] = person
				ps[person.id].index = index++
			}
			
			this.persons = ps
			this.implicitlyMark()
		},
		
		get(arg: Person|ID): Person|null {
			if (isPerson(arg)) {
				return this.persons[arg.id] ?? null
			}
			
			return this.persons[arg] ?? null
		},
		
		/**
		 * Add a person
		 *
		 * @param {Person} person
		 */
		add(person: Person) {
			let p: Person = {
				id: generateID('person-'),
				external_id: generateID('person-external-'),
				name: person.name,
				firstname: person.firstname,
				type: person.type,
				role: getRoleForType(person.type),

				contact: {
					email: person.contact ? person.contact.email : null,
					phone: {
						national: person.contact && person.contact.phone ? person.contact.phone.national : null,
						international: null
					}
				},

				address: {
					address: null,
					postcode: null,
					city: null,
					country: null,
					copied: false,
				},

				billing_address: {
					address: null,
					postcode: null,
					city: null,
					country: null,
					use_person_address: null,
					use_estate_address: null,
					use_custom_address: null,
				},

				on_behalf: {
					name: null,
					myself: null,
					power_of_attorney_received: null,
					images: []
				},

				absent: false,

				birthdate: {
					iso: null,
					timestamp: person.birthdate?.timestamp ?? null
				},

				contact_id: 0,
				signature: null,
				signature_type: null,
				selected: false,
				content_agreed: false,
				marked: person.marked || false,
				validated: false,
				index: this.list.length
			}
			
			this.persons[p.id] = p
			this.unmarkAll()
		},
		
		/**
		 * Set the index for all persons
		 */
		reindex() {
			let index = 0
			
			forEachObject(this.persons, (value) => {
				value.index = index++
			})
		},
		
		/**
		 * Update a person in the store
		 *
		 * @param {Person} person
		 */
		update(person: Person) {
			let p = this.get(person)
			
			if (p) {
				let backup = clone(p)
				this.persons[person.id] = person
				person.selected = backup.selected
				
				if (backup.marked) {
					person.marked = true
				}
			}
		},
		
		/**
		 * Delete a person from the store
		 *
		 * @param {Person | ID} person
		 */
		delete(person: Person|ID) {
			const p = this.get(person)
			
			if (p) {
				delete this.persons[p.id]
				this.reindex()
				this.implicitlyMark()
			}
		},
		
		/**
		 * Select one or more persons
		 *
		 * @param {Person | Person[] | ID | ID[] | null} arg
		 */
		select(arg: Person|Person[]|ID|ID[]|null) {
			let payload: any = null
			
			if (Array.isArray(arg)) {
				payload = []
				
				for (let a of arg) {
					if (typeof(a) == 'string') {
						payload.push(a)
					} else if (a.id) {
						payload.push(a.id)
					}
				}
			}
			
			if (Array.isArray(payload)) {
				let selections = {}
				
				for (let personID of payload) {
					selections[personID] = true
				}
				
				for (let id in this.persons) {
					if (this.persons.hasOwnProperty(id)) {
						this.persons[id].selected = selections[id] ?? false
					}
				}
			} else if (payload === null) {
				for (let id in this.persons) {
					if (this.persons.hasOwnProperty(id)) {
						this.persons[id].selected = false
					}
				}
			} else {
				log.warn('Invalid person selection argument', arg, payload)
			}
		},
		
		/**
		 * Set the signature for a person in the store
		 *
		 * @param {Person | ID} person
		 * @param {Signature} signature
		 */
		setSignature(person: Person|ID, signature: Signature) {
			const p = this.get(person)
			
			if (p) {
				p.signature = signature
			}
		},
		
		/**
		 * Set the validity of a signature for a person in the store
		 *
		 * @param {Person | ID} person
		 *
		 * @param {string} validity
		 */
		setSignatureValidity(person: Person|ID, validity: string) {
			const p = this.get(person)
			
			if (p) {
				p.signature_validity = validity
			}
		},
		
		/**
		 * Set the signature type for a person in the store
		 *
		 * @param {Person | ID} person
		 * @param {AnySignatureType|null} type
		 */
		setSignatureType(person: Person|ID, type: AnySignatureType|null) {
			const p = this.get(person)
			
			if (p) {
				p.signature_type = type
			}
		},
		
		/**
		 * Set whether a person is absent in the store
		 *
		 * @param {Person | ID} person
		 * @param {boolean} absent
		 */
		setAbsent(person: Person|ID, absent: boolean) {
			const p = this.get(person)
			
			if (p) {
				p.absent = absent
			}
		},
		
		/**
		 * Set on behalf data for a person in the store
		 *
		 * @param {Person | ID} person
		 * @param {any} name
		 * @param {any} myself
		 * @param {any} powerOfAttorneyReceived
		 * @param {any} images
		 */
		setOnBehalf(person: Person|ID, { name, myself, powerOfAttorneyReceived, images }) {
			const p = this.get(person)
			
			if (p) {
				if (p.on_behalf) {
					if (name !== undefined) {
						p.on_behalf.name = name
					}
					
					if (myself !== undefined) {
						p.on_behalf.myself = myself
					}
					
					if (powerOfAttorneyReceived !== undefined) {
						p.on_behalf.power_of_attorney_received = powerOfAttorneyReceived
					}
					
					if (images !== undefined) {
						p.on_behalf.images = images
					}
				} else {
					p.on_behalf = {
						name: name ?? null,
						myself: myself ?? null,
						power_of_attorney_received: powerOfAttorneyReceived ?? null,
						images: []
					}
				}
			}
		},
		
		/**
		 * Set whether the person agreed to the contents of the protocols (if enabled) in the store
		 * @param {Person | ID} person
		 * @param {boolean} agreed
		 */
		setContentAgreed(person: Person|ID, agreed: boolean) {
			const p = this.get(person)
			
			if (p) {
				p.content_agreed = agreed
			}
		},
		
		/**
		 * Set the address of a person in the store
		 *
		 * @param {Person | ID} person
		 * @param {PersonsAddressType} address
		 */
		setAddress(person: Person|ID, address: PersonsAddressType) {
			const p = this.get(person)
			
			if (p) {
				p.address = address
			}
		},
		
		/**
		 * Reset the signature of a person in the store
		 * @param {Person | ID} person
		 */
		resetSignature(person: Person|ID) {
			const p = this.get(person)
			
			if (p) {
				p.signature = null
				p.signature_type = null
				p.absent = false
			}
		},
		
		/**
		 * Reset all signatures in the store
		 */
		resetAllSignatures() {
			forEachObject(this.persons, (person) => this.resetSignature(person))
		},
		
		/**
		 * Mark a person as validated in the store
		 *
		 * @param {Person | ID} person
		 */
		validatePerson(person: Person|ID) {
			let p = this.get(person)
			
			if (p) {
				p.validated = true
			}
		},
		
		/**
		 * Mark a person as invalidated in the store
		 *
		 * @param {Person | ID} person
		 */
		invalidatePerson(person: Person|ID) {
			let p = this.get(person)
			
			if (p) {
				p.validated = false
			}
		},
		
		/**
		 * Mark all persons as invalidated in the store
		 */
		invalidateAllPersons() {
			forEachObject(this.persons, person => this.invalidatePerson(person))
		},
		
		/**
		 * @deprecated To be replaced by the new ProductPersonChooser with no markings on a person
		 * @param {Person | ID} person
		 */
		mark(person: Person|ID) {
			let p = this.get(person)
			
			if (p) {
				p.marked = true
			}
		},
		
		/**
		 * @deprecated To be replaced by the new ProductPersonChooser with no markings on a person
		 * @param {Person | ID} person
		 */
		unmark(person: Person|ID) {
			let p = this.get(person)
			
			if (p) {
				p.marked = false
			}
		},
		
		/**
		 * @deprecated To be replaced by the new ProductPersonChooser with no markings on a person
		 */
		unmarkAll() {
			for (let person of this.list) {
				person.marked = false
			}
		},
		
		/**
		 * @deprecated To be replaced by the new ProductPersonChooser with no markings on a person
		 */
		implicitlyMark() {
			if (this.sellers.length == 1) {
				this.mark(this.sellers[0])
			}
			
			if (this.buyers.length == 1) {
				this.mark(this.buyers[0])
			}
		}
	}
})

export const createAcquisitUIPersonsStoreAdapter = () => {
	const personsStore = usePersonsStore()
	
	const persons = computed<Person[]>({
		get() {
			return Object.values(personsStore.persons)
		},
		
		set(value) {
			personsStore.persons = createRecord(value, p => p.id)
		}
	})
	
	return {
		persons,
		byID: computed(() => createRecord(persons.value, p => p.id)),
		add: personsStore.add,
		update: personsStore.update,
		delete: personsStore.delete
	}
}